<template>
    <div id="widget" :key="componentKey" class="bankiros-template-slider">
        <h3 v-if="params" >Теперь с кешбэком</h3>

        <div class="bankiros-template-slider__block mt-4">
            <Slider
                class="slider"
                v-model='anketa.initialPayment.value'
                v-bind='anketa.initialPayment'
                :min='MinSum'
                :max='MaxSum'
                :step='1000'
                :sliderColors="sliderColors"
                @input='anketa.initialPayment.input'
            >
                <div class="slider__block" slot="top">
                    <p class="m-0 is-size-2 light-grey--text label">Сумма</p>

                    <p class="m-0 is-size-3  value">{{ anketa.initialPayment.value | moneyFormatWithSpace }}</p>
                </div>
            </Slider>
        </div>

        <div class="bankiros-template-slider__block mt-4">
            <Slider
                class="slider"
                v-model='anketa.term.value'
                v-bind='anketa.term'
                :slider-array='sliderPeriod'
                :min='1'
                :max='sliderPeriod.length'
                :step='1'
                :sliderColors="sliderColors"
                @input='anketa.term.input'
                :metricGoalName="'slider_term'"
            >
                <div class="slider__block" slot="top">
                    <p class="m-0 is-size-2 light-grey--text label">Срок</p>
                    <p class="m-0 is-size-3  value">{{anketa.term.valueTerm}} {{ anketa.term.valueTerm | month }}</p>
                </div>
            </Slider>
        </div>

        <div class="d-flex align-center justify-space-between mt-2">
            <p :class="{'is-size-2': $root.isMobile}" class="is-size-3 m-0">Ежемесячный платеж</p>
            <p :class="{'is-size-4': $root.isMobile}" class="is-size-5 m-0"> {{ anketa.conditions.monthlyPayment | moneyFormatWithSpace }} ₽</p>
        </div>

        <BankirosSliderCashback :params="params" />

        <v-btn
            @click="changeStatusModal()"
            data-modal="form-modal"
            id="get-money"
            class="rectangle-button mt-6"
        >
            Получить {{ anketa.conditions.initialPayment | moneyFormatWithSpace }} ₽
        </v-btn>
    </div>
</template>

<script>
import Slider from "@/components/Slider.vue";
import {moneyFormatWithSpace} from "@/components/helpers/MoneyFormat";
import {month} from "@/components/helpers/DeclOfNum";
import BankirosSliderCashback from "@/components/bankiros/slider/SliderCashback.vue";

export default {
    components: {BankirosSliderCashback, Slider},
    name: 'BankirosTemplateSlider',

    computed: {
        anketa() {
            return this.$root.anketa;
        },
        connector() {
            return this.$root.connector;
        },
        cashback: {
            get() {
                return moneyFormatWithSpace((this.anketa.initialPayment.value / 100) * this.anketa.term.percent * (this.anketa.term.valueTerm - 1));
            },
        },
        minTerm: {
            get() {
                return Math.min.apply(null, this.sliderPeriod);
            },
        },
        maxTerm: {
            get() {
                return Math.max.apply(null, this.sliderPeriod);
            },
        },
        templateText: {
            set() {
                return this.anketa.conditions.initialPayment > 40000 ? false : true;
            },
            get() {
                return this.anketa.conditions.initialPayment > 40000 ? false : true;
            },
        },
    },
    data: () => ({
        sliderColors: {
            bg: '#95CE5A',
            track: '#DADEE2'
        },
        MinSum: 3000,
        MaxSum: 60000,
        sliderPeriod: [1],
        params: '',
        showSliderComplited: {
            status: false,
            error: {
                status: false,
                text: "Системная ошибка",
            },
        },
        componentKey: 0
    }),
    filters: {
        moneyFormatWithSpace,
        month
    },
    created() {
        let urlParams = new URLSearchParams(window.location.search);
        this.anketa.mindboxId = urlParams.get("c");

        this.getFirstDate(urlParams);
        this.limitSumDefinitions(urlParams);
    },
    watch: {
        reRenderComponent: function () {
            this.forceRerender();
        },
    },
    methods: {
        limitSumDefinitions(urlParams) {
            if(urlParams.get("e")) this.$root.mindbox("Посетил страницу. Лимит 15k");
            if(urlParams.get("w")) this.$root.mindbox("Посетил страницу. Лимит 30k");
            if(urlParams.get("o")) this.$root.mindbox("Посетил страницу. Лимит 50k");
            if(urlParams.get("h")) this.$root.mindbox("Посетил страницу. Лимит 80k");
            if(urlParams.get("t")) this.$root.mindbox("Посетил страницу. Лимит 100k");
            if(urlParams.get("m")) this.$root.mindbox("Посетил страницу. Лимит 150k");
            if(urlParams.get("r")) this.$root.mindbox("Посетил страницу. Лимит 100k. NearPrime");
            if(urlParams.get("y")) this.$root.mindbox("Посетил страницу. Лимит 500k. NearPrime");
            if(urlParams.get("nr")) this.$root.mindbox("Посетил страницу. Лимит 20к. Нерезидент");
        },
        changeStatusModal() {
            // this.$root.metrika98070141.reachGoal("get_money");
            this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("get_money");

            this.$root.mindbox("Выбрал сумму на калькуляторе");
            this.$root.anketa.isOpenModal = true;
        },
        forceRerender() {
            this.componentKey += 1;
        },
        getFirstDate(urlParams) {
            this.connector
                .isMainInformationCalc(urlParams.get("s"))
                .then((response) => {
                    if (response.success && response.slider_params.maxSum !== 0) {
                        this.showSliderComplited.status = true;
                        this.anketa.firstDateSlider = this.getObjSort(response.slider_params.listStocks);

                        if (urlParams.get("ac") !== null) {
                            this.anketa.actionInfo = true;
                            this.params = 'ac'
                        }

                        else if (urlParams.get("acnew") !== null) {
                            this.anketa.actionInfo = true
                            this.params = 'acnew'
                        }
                        for (let k in this.anketa.firstDateSlider) {
                            this.anketa.firstDateSlider[k].periodStr = String(this.anketa.firstDateSlider[k].period);
                        }
                        this.sliderPeriod = this.getsliderPeriod(this.anketa.firstDateSlider);

                        this.MaxSum = response.slider_params.maxSum;
                        this.MinSum = response.slider_params.minSum;

                        if (urlParams.get("sum")) {
                            this.anketa.initialPayment.value = +urlParams.get("sum") * 1000;
                        } else {
                            this.anketa.initialPayment.value = response.slider_params.maxSum;
                        }

                        for (let i in this.sliderPeriod) {
                            if (this.sliderPeriod[i] == response.slider_params.defaultPeriod) {
                                this.anketa.term.value = parseInt(i) + 1;
                            }
                        }
                        this.anketa.conditions.calculate(this.anketa.initialPayment.value, this.anketa.term.value);
                        return this.anketa.firstDateSlider;
                    } else {
                        this.anketa.sliderParams = false;
                    }
                })
                .catch((error) => {
                    this.showSliderComplited = {
                        status: false,
                        error: {
                            status: true,
                            text: error,
                        },
                    };
                });
        },
        getObjSort(obj) {
            return obj.sort((a, b) => (a.period > b.period ? 1 : -1));
        },
        getsliderPeriod(obj) {
            let array = [];
            for (let k in obj) {
                array.push(obj[k].period);
            }
            return array;
        },

    }
}
</script>

<style lang="scss">
.bankiros {
    .bankiros-template-slider {
        background: rgba(255, 255, 255, 0.70);
        border: 1px solid #E8ECEF;
        backdrop-filter: blur(5px);
        padding: 20px;
    }

    .slider {
        padding: 0;

        .v-slider__tick {
            background-color: inherit !important;
        }

        .col {
            padding: 0 !important;
        }

        .v-slider--horizontal {
            margin: 0 !important;
        }

        .row {
            margin-left: 0 !important;
            margin-right: 0 !important;

            margin-top: -16px !important;
        }

        .v-slider {
            .v-slider__track-container {
                height: 5px !important;
                border-radius: 36.5px;
            }

            .v-slider__thumb {
                width: 20px;
                height: 20px;
                background-color: white !important;
                //box-shadow: 0px 1px 4px rgba(10, 10, 13, 0.15);
                box-shadow: 0px 1px 3px 0px #ADB4BF;

                &:before {
                    width: 8px !important;
                    height: 8px !important;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) !important;
                    background-color: var(--v-primary-base);
                    opacity: 1 !important;
                }

                &:after {
                    content: none;
                }
            }
        }

        &__block {
            border-radius: 3px;
            border: 1px solid #DADEE2;
            background: #fff;
            position: relative;
            padding: 11px 15px;

            .label {
                display: inline-block;
                position: absolute;
                top: 0;
                padding: 0 4px;
                background-color: #fff;
                transform: translate(-4px, -50%);
            }
        }
    }
}

</style>
