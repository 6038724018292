<template>
    <div>
        <div :class="{'isError': anketa.promocode.promocodeOptions.error}" class="modal-screen-input">
            <v-text-field
                v-mask="mask"
                v-model="fieldValue"
                autocomplete="false"
                :placeholder="placeholder || virtualPlaceholder"
                :type="!isHidden ? type : 'password'"
                solo
                flat
                :outlined="outlined"
                :errorMessages="errorMessages"
                :hide-details="false"
                background-color="white"
                validate-on-blur
                :rules="rules"
                :disabled="isDisabled"
                @blur="onBlur"
                @focus="onFocus"
                :key="updateKey"
                :autofocus="autofocus"
                ref="promocode"
                :class="$root.appType('travelata') ? 'travelata_promocode' : ''"
                >

            </v-text-field>

            <div
                v-if="!anketa.promocode.onFocus && anketa.promocode.value === ''"
                @click="promocodeInfoHandler"
                :class="$root.appType('travelata') ? 'travelata_promocode_info' : 'promocode_info'"
                class="promocode_info"
                >
                <img
                    v-if="($root.appType('cash') || $root.appType('auto')) && anketa.promocode.showPromocodeLogo"
                    src="../../assets/img/promocode/promocode_img_cash.png"
                />
                <img
                    v-if="$root.appType('nordwind') && anketa.promocode.showPromocodeLogo"
                    src="../../assets/img/promocode/promocode_img_nordwind.png"
                />
                <img
                    v-if="$root.appType('vtb') && anketa.promocode.showPromocodeLogo"
                    src="../../assets/img/promocode/promocode_img_vtb.png"
                />
                <img
                    v-if="($root.appType('travelata') || $root.appType('bankiros')) || $root.appType('southwind') && anketa.promocode.showPromocodeLogo"
                    src="../../assets/img/travelata/travelata_ticket.svg"
                />
                <span
                    v-if="!anketa.promocode.promocodeOptions.error"
                    >{{ anketa.promocode.promocodeInfo }}
                </span>
                <span
                    v-if="anketa.promocode.promocodeOptions.error"
                    @click="errorTextHandler"
                    class="promocode_error"
                    >Неверный промокод
                </span>
            </div>
        </div>

        <label class="is-size-5">{{ comment }}</label>
        <slot name="inputAdditionalInfo"></slot>
    </div>
</template>

<script>



export default {
    props: {
        value: [Number, String, Boolean],
        label: String,
        comment: String,
        append: String,
        mask: String,
        maskValue: String,
        type: String,
        rules: Array,
        placeholder: String,
        errorMessages: String,
        outlined: Boolean,
        hideValue: {
            type: Boolean,
            default: () => undefined,
        },
        disabled: Boolean,
        replaceEngToRu: {
            type: Boolean,
            default: true,
        },

    },



    mounted() {
        if (this.label === "Номер счета") {
            document.addEventListener("click", (e) => {
                if (
                    !e.target.closest(".tooltip") &&
                    !e.target.closest(".tooltip-position")
                ) {
                    this.isTooltipFind = false;
                }
            });
        }

        console.log('ref promocode', this.$refs.promocode)
    },

    computed: {
        fieldValue: {
            get() {
                return this.value
            },
            set(val) {
                console.log(val)
                // let oldval = val;
                this.anketa.promocode.value = val

                if (val != this.value) {

                    val = val.replace(/[^а-яА-ЯёЁa-zA-Z0-9]/g, '')
                    if (val.length >= 30){
                        val = val.substring(0, 30)
                    }

                    this.$emit("input", val);
                    // updateKey обновляет компонент, позволяет изменять value внутри компонента согласно регулярки
                    // autofocus компонент НЕ переходит в blur после каждого нажатия
                    this.updateKey++;
                    this.autofocus = true;
                }
            },
        },
        appendText() {
            return this.append;
        },
        virtualPlaceholder() {
            if (this.mask) {
                return this.mask.replace(/#/g, "0");
            } else {
                return null;
            }
        },

        isDisabled() {
            return this.anketa.promocode.isDisabled;
        },

        anketa(){
            return this.$root.anketa
        },

        promoFieldColor(){
            let textColor = null
            let promoError = this.anketa.promocode.promocodeOptions.error
            console.log('promocode error', promoError)
            if((this.$root.appType('cash') || this.$root.appType('auto')) && promoError){
                textColor = '#EF4058'

            }
            else if(this.$root.appType('nordwind') && promoError) {
                textColor = '#D6181E'

            }
            else if(this.$root.appType('vtb') && promoError) {
                textColor = '#D6181E'
            }
            else if(this.$root.appType('travelata') && promoError) {
                textColor = '#FA656E'
            }
            else {
                textColor = '#0A0A0D'
            }

            console.log('promo Color', textColor)
            return textColor
        },

    },
    data() {
        return {
            updateKey: 0,
            autofocus: false,
            isTooltipFind: false,
            prevValue: "",
            isHidden: this.hideValue,
        };
    },

    methods: {
        onBlur(e) {
            this.$emit("blur", e);
            console.log('bluring')
            this.anketa.promocode.onFocus = false
            // this.anketa.promocode.promocodeOptions.error = false
            this.anketa.promocode.showPromocodeLogo = true

        },
        onFocus() {
            this.anketa.promocode.onFocus = true
            if(this.anketa.promocode.promocodeOptions.error){
                this.anketa.promocode.promocodeOptions.error = false

            }
            // if (this.value === "" && (this.maskValue ?? this.mask)) {
            //     const inputValue = this.maskValue || this.mask;
            //     const focusValue = inputValue.replace(/[\s- #]/g, "");
            //     console.log('focusValue', focusValue)
            // }
        },

        promocodeInfoHandler(){
            if(!this.anketa.promocode.isDisabled){
                this.anketa.promocode.onFocus = true
            }
            // this.anketa.promocode.showPromocodeLogo = true
            this.$refs.promocode.focus()

        },

        errorTextHandler(){
            this.anketa.promocode.promocodeOptions.error = false
            this.anketa.promocode.onFocus = true
            this.$refs.promocode.focus()
        },

    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_travelata-variables.scss';

.promocode {
    &_info{
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 16px;
        font-weight: 600;
        line-height: 18.75px;
        letter-spacing: -0.4px;
        color: #0A0A0D;

        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;

        // &::before {
        //     content: '';
        //     background: url('./../../assets/img/promocode/promocode_img_cash.png');
        //     display: block;
        //     width: 20px;
        //     height: 18px;
        //     position: absolute;
        //     top: -3px;
        //     left: -30px;
        // }

        // & > img {
        //     position: absolute;
        //     // top: 10px;
        //     // left: -20px;
        // }
    }

    &_error {
        color: v-bind(promoFieldColor);
    }
}
.travelata {
    &_promocode {

        & ::v-deep input:disabled {
            background-color: #fff !important;
        }
    }
    &_promocode_info {
        @include tr-text(18px, 600, 18px);
        font-family: 'OpenSansSB';
        color: #333;
    }


}
</style>
