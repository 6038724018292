<template>
    <div
        class="sure-tooltip"
        >
        <div class="d-flex align-center justify-space-between">
            <h4 class="sure-tooltip_title">Вы уверены?</h4>

            <v-btn fab elevation="1" class="modal-icon">
                <img src="@/assets/img/close.svg" alt="logotype" />
            </v-btn>
        </div>

        <p
            class="sure-tooltip_subtitle">
            Cтрахование помогает обезопасить себя от возможных финансовых потерь
            и непредвиденных ситуаций: смерть, потеря дохода, нетрудоспособность
            и т.д.
        </p>
        <h4
            class="sure-tooltip_second-title"
            >Рекомендуем подключить услугу!
        </h4>

        <p v-if="errorAuthTextApi" class="my-2 error--text">
            {{ errorAuthTextApi }}
        </p>

        <div class="sure-tooltip_buttons">
            <v-btn
                @click="$emit('input')"
                class="active sure-tooltip_buttons_next"
                >Далее
            </v-btn>
            <p
                @click="disable"
                class="sure-tooltip_buttons_text">Отключить
            </p>
        </div>
    </div>
</template>

<script>

export default {
    methods: {
        disable() {
            this.$root.connector
                .isInsurancePackage(0)
                .then((response) => {
                    if (response.success) {
                        this.$emit("input");
                        this.$emit("disableTooltip");
                        this.$root.anketa.creditInformation = response.info;
                    } else {
                        this.errorAuthTextApi = response.message;
                    }
                })
                .catch((error) => {
                    this.errorAuthTextApi = error;
                });
        },

    },

    data: () => ({
        errorAuthTextApi: "",
    }),
    computed: {
        chooseBorder(){
            let cssClass = null
            if(this.$root.appType('vtb')){
                cssClass = 'vtb-border-button'
            }else if(this.$root.appType('nordwind')) {
                cssClass = 'nordwind-border-button'
            }else if(this.$root.appType('cash') || this.$root.appType('auto')) {
                cssClass = 'cash-border-button'
            }else if(this.$root.appType('bankiros')) {
                cssClass = 'rectangle-button'
            }


            return cssClass
        },

    }
};
</script>

<style lang="scss" scoped>

.sure-tooltip {
    position: absolute;
    padding: 24px;
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    background-color: #fff;
    bottom: 60px;
    z-index: 100;



    &_title {
        font-family: Neris;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.4px;
        margin-bottom: 16px;
        color: #333;
    }

    &_subtitle {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: #848485;
        text-align: left;

    }

    &_second-title {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: #0A0A0D;
        text-align: left;
        margin-bottom: 50px;
    }

    &_buttons {
        display: flex;
        flex-direction: row-reverse;
        gap: 16px;
        align-items: center;

        &_next {
            width: 128px;
            height: 27px !important;
            border-radius: 8px;
            background-color: #AB292D !important;
            color: #fff;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 900;
            line-height: 16px;
            letter-spacing: -0.4px;
            text-transform: none;
        }

        &_text {
            font-family: Neris;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.4px;
            color: #848485;
            margin: 0;
            cursor: pointer;
        }
    }

    .modal-icon {
        width: 24px !important;
        height: 24px !important;
    }
}

.cash-border-button {
    height: 42px !important;
}
.nordwind-border-button {
    height: 42px !important;
}


</style>
