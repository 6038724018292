<template>
    <div class="block-4-body mt-0" id="getMoney">
        <div class="block-4-body">
            <h1>Как получить деньги</h1>
            <div class="discount-info">
                <div class="discount-info-item discount-item-1">
                    <div class="discount-info-block">
                        <img
                            class="discount-image"
                            src="@/assets/img/auto/clocks.png"
                        >
                    </div>
                    <h4>Подайте заявку</h4>

                    <p class="discount-info-text">Оставьте короткую заявку это займет несколько минут
                    </p>
                </div>
                <div class="discount-info-item discount-item-2">
                    <div class="discount-info-block">
                        <img
                            class="discount-image"
                            src="@/assets/img/auto/camera.png"
                        >
                    </div>
                    <h4>Рассмотрение</h4>

                    <p class="discount-info-text">После предварительного одобрения понадобятся лишь фото автомобиля
                    </p>
                </div>
                <div class="discount-info-item discount-item-3">
                    <div class="discount-info-block">
                        <img
                            class="icon-image"
                            src="@/assets/img/auto/card.png"
                        >
                    </div>
                    <h4>Получите деньги!</h4>
                    <p class="discount-info-text">
                        На Ваш банковский счет
                    </p>
                </div>
            </div>
        </div>
        <v-btn
            class="modal-open discount-button button_center rectangle-button"
            data-modal="form-modal"
            @click="$vuetify.goTo('#widget', 500)"
        >Получить деньги
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "BlockHTML4Cash.vue",
    methods: {
        urlParams(param) {
            const urlLocation = location.search.split('&')
            return urlLocation.includes(param)
        },
    }
}
</script>

<style scoped lang="scss">
.discount-image {
    width: auto !important;
}

.discount-info-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .discount-info-text {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.block-4-body .discount-info .discount-info-item .discount-info-block {
    height: auto !important;
}

.block-4-body {
    margin-bottom: 0;
}

h4 {
    color: #0A0A0D;

    text-align: center;
    font-family: Neris;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
}

.discount-info-text {
    color: #0A0A0D;
    text-align: center;
    font-family: Neris;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3em;
}

a {
    color: #007AFF !important;
}

.discount-button {
    border-radius: 12px !important;
    margin-bottom: 0 !important;
}

@media (max-width: 480px) {
    h4 {
        font-size: 20px;
    }

    .discount-info-text {
        font-size: 16px !important;
        margin-top: 12px !important;
    }
}
</style>
