<template>
    <div class="timer">
        <span class="timer_label">{{label}}</span>
        <span
            class="timer_timer-value"
            >{{timerValue}}
        </span>
        <span
            class="timer_timer-append"
            >{{append}}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        seconds: Number,
        append: String,
        colorTimerValue: {
            type: String,
        },
        colorAppend: {
            type: String,

        }
    },
    mounted() {
        this.timerValue = this.seconds || 0;
        this.interval = setInterval(this.decrease, 1000);
    },
    data: () => ({
        interval: null,
        timerValue: 0,
    }),
    methods: {
        decrease() {
            if (this.timerValue > 0) {
                this.timerValue--
            } else {
                clearInterval(this.interval);
                this.$emit('complete');
            }
        },
    },


}
</script>

<style lang="scss" scoped>

.timer {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: #848485;
    margin-top: 2px;

    &_timer-value{
        margin-left: 2px;
    }
}
//.timer_timer-value {
//    margin: 2px;
//}
//.travelata {
//    &_timer_timer-value {
//        color: #059BCE;
//        margin-left: 2px;
//    }
//
//    &_timer_append {
//        margin-left: 2px;
//        color: #059BCE;
//    }
//}
//.timer_timer-value_color {
//    color: v-bind(colorTimerValue);
//}
//.timer_append_color {
//    color: v-bind(colorAppend);
//}
</style>
