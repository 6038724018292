<template>
    <div>
        <div class="main-option-for-app">
            <h1>Восстановление пароля</h1>
            <p></p>
        </div>

        <v-form ref="form">
            <v-row>
                <Field anketa-name="phone"/>
                <Field cols="12" anketa-name="birthdate"/>
            </v-row>

            <v-btn
                @click="goAuth"
                :loading="loading"
                :disabled="loading"
                class="rectangle-button"
            >Далее
            </v-btn>
        </v-form>
        <p class="mini-text text-center">Новый пароль отправим в смс на указанный номер</p>
        <p
            class="error-text"
            v-show="errorAuthTextApi.status"
        >{{ errorAuthTextApi.text }}</p>
    </div>
</template>

<script>
import Field from "@/components/Field.vue";

export default {
    name: "RecoveryPass",
    components: {Field},
    comments: {},
    data: () => ({
        loading: false,
        errorAuthTextApi: {
            status: false,
            text: ''
        }
    }),
    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },
        isNordwind(){
            return this.$root.state.isNordwind()
        }
    },
    methods: {
        takeParentsInformation(flag) {
            const data = {
                success: flag,
                prev: 'recoveryPass',
                next: 'auth',
            }

            this.$emit('changeTemplate', data);
        },
        goAuth() {
            if (this.$refs.form.validate()) {
                if (!sessionStorage.getItem('password_reset')) {
                    if(this.isNordwind){
                        this.$root.metrika96799237.reachGoal("password_reset");
                    }else {
                        this.$root.metrika88149184.reachGoal("password_reset");
                    }
                    this.$root.mindbox("Восстановил пароль");
                }

                sessionStorage.setItem('password_reset', 'true')

                this.loading = true;
                this.connector.isRecoveryPassword(
                    this.anketa.phone.valueFirstDigitIs9(), this.anketa.birthdate.dateValue()
                ).then(response => {
                    if (response.success) {
                        this.takeParentsInformation(true);
                    } else {
                        this.errorAuthTextApi.status = true;
                        return this.errorAuthTextApi.text = response.message;
                    }
                }).catch(error => {
                    this.errorAuthTextApi.status = true;
                    this.errorAuthTextApi.text = error;
                })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.validFormBIK = true;
            }
        }
    },
}
</script>

<style scoped>
</style>
