<template>
  <!--form-->
    <v-navigation-drawer
        class="form-modal"
        id="form-modal"
        temporary
        touchless
        fixed
        right
        :permanent="anketa.fullScreenModal"
        v-model="anketa.isOpenModal"
        >
        <div
            :class="{ fullScreenModal: anketa.fullScreenModal }"
            class="form-display"
            >
            <div class="modal-navbar mb-8">
                <div
                    class="modal-icon modal-back"
                    @click="backPrevPage"
                    v-if="backPrevButtonRules"
                    >
                    <v-btn icon>
                        <img src="../../assets/img/arrow.svg" alt="logotype" />
                    </v-btn>
                </div>

                <v-btn
                    fab
                    elevation="1"
                    class="modal-icon modal-close btn_round"
                    @click="anketa.isOpenModal = false"
                    >
                    <img src="../../assets/img/close.svg" alt="logotype" />
                </v-btn>
            </div>

    <!-- Акция \ Нет акции + счет и карта -->
            <div class="modal-screen modal-screen-1">
                <Modals :router="router" />
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script>
import Modals from "./Modals.vue";
export default {
  components: { Modals },
  name: "FormHTML",
  data: () => ({
    router: {
      phone: false,
      account: false,
      registration: false,
      auth: false,
      statusWait: false,
      recoveryPass: false,
      needMoreData: false,
      accessApplication: false,
      declined: false,
      smsPage: false,
      back: false,
      checkApplication: false,
      history: "",
    },
  }),

  created() {
    let urlParams = new URLSearchParams(window.location.search);
    this.getClientStatus(urlParams);
  },

  computed: {
    anketa() {
      return this.$root.anketa;
    },
    connector() {
      return this.$root.connector;
    },

    backPrevButtonRules(){
      return this.router.smsPage && !this.anketa.codeSMS.success || this.router.auth && !this.anketa.codeSMS.success
    }
  },
  methods: {
    getClientStatus(urlParams) {
      const data = {
        client_id: urlParams.get("client"),
        new_client_token: urlParams.get("app_id"),
        token_timestamp: urlParams.get("ts"),
      };

      this.connector
        .isStatusApplication(data)
        .then((response) => {
          if (!response.redirect_auth) {
            this.anketa.client_id = urlParams.get("client");
            this.anketa.new_client_token = urlParams.get("app_id");
            this.anketa.token_timestamp = urlParams.get("ts");
            this.router.statusWait = true;
            this.anketa.isOpenModal = true;
            this.anketa.fullScreenModal = true;
            this.anketa.withoutToken = true;
          } else {
            // this.router.account = true;
            this.router.phone = true;
          }
        })
        .catch(() => {
          // this.router.account = true;
          this.router.phone = true;
        });
    },

    backPrevPage() {
      for (const [key] of Object.entries(this.router)) {
          this.changeRouter(key, this.router.history);
          break;
      }
    },

    changeRouter(prev, next) {
      if (prev.length != 0 && next.length != 0) {
        for (const k in this.router) {
          this.router[k] = false;
        }
        this.router[next] = true;
        this.router.history = prev;
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn_round {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
</style>
