<template>
  <div>
    <v-row
      dense
      class="card_list__body"
    >
      <v-col
        class="card_list__item"
        v-for="(cardItem, index) in card.body"
        :key="index"
        v-bind="cardItem.cols || {lg: 12 / card.body.length, cols: 6}"
      >
        <div v-if="cardItem.image">
          <div class="img-block mb-2">
            <img
              :src="cardItem.image"
              class="card_list__item-image"
            />
          </div>

          <p class="card_list__item-text" v-html="cardItem.text"></p>
        </div>

        <div v-if="cardItem.list" class="list">
          <p class="card_list__item-text mb-2" v-html="cardItem.text"></p>

          <ul class="card-list" >
            <li v-for="(listItem, index) in cardItem.list" :key="index">
              {{ listItem }}
            </li>
          </ul>
        </div>

      </v-col>

    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    card:
      {
        type: [Array, Object],
        require: true,
      },
  },
}
</script>

<style lang="scss" scoped>
</style>