import { render, staticRenderFns } from "./BlockHTML4Southwind.vue?vue&type=template&id=16e5cf68&scoped=true"
import script from "./BlockHTML4Southwind.vue?vue&type=script&lang=js"
export * from "./BlockHTML4Southwind.vue?vue&type=script&lang=js"
import style0 from "./BlockHTML4Southwind.vue?vue&type=style&index=0&id=16e5cf68&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16e5cf68",
  null
  
)

export default component.exports