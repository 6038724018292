<template>
    <div
        :class="$root.appType('travelata') ? 'travelata_sure-tooltip' : ''"
        class="tooltip sure-tooltip"
        >
        <div class="d-flex align-center justify-space-between">
            <h4 :class="$root.appType('travelata') ? 'travelata_sure-tooltip_title' : ''">Вы уверены?</h4>

            <v-btn fab elevation="1" class="modal-icon">
                <img src="../../assets/img/close.svg" alt="logotype" />
            </v-btn>
        </div>

        <p
            :class="$root.appType('travelata') ? 'travelata_sure-tooltip_text' : ''"
            class="mb-2 mt-4 text-left">
            Cтрахование помогает обезопасить себя от возможных финансовых потерь
            и непредвиденных ситуаций: смерть, потеря дохода, нетрудоспособность
            и т.д.
        </p>
        <h4
            :class="$root.appType('travelata') ? 'travelata_sure-tooltip_subtitle' : ''"
            class="text-left mt-3"
            >Рекомендуем подключить услугу!
        </h4>

        <p v-if="errorAuthTextApi" class="my-2 error--text">
            {{ errorAuthTextApi }}
        </p>

        <div class="sure-tooltip_buttons" :class="$root.appType('travelata') ? 'travelata_sure-tooltip_buttons' : ''">
            <v-btn
                @click="$emit('input')"
                :class="[chooseBorder, $root.appType('travelata') ? 'travelata_sure-tooltip_next-button' : '']"
                class="active  w-100 mb-3 mt-6"
                >Далее
            </v-btn>
            <p
                :class="$root.appType('travelata') ? 'travelata_sure-tooltip_pointer' : 'w-100'"
                @click="disable"
                class="pointer m-0  text-center">Отключить
            </p>
        </div>
    </div>
</template>

<script>

export default {
    methods: {
        disable() {
            this.$root.connector
                .isInsurancePackage(0)
                .then((response) => {
                    if (response.success) {
                        this.$emit("input");
                        this.$emit("disableTooltip");
                        this.$root.anketa.creditInformation = response.info;
                    } else {
                        this.errorAuthTextApi = response.message;
                    }
                })
                .catch((error) => {
                    this.errorAuthTextApi = error;
                });
        },

    },

    data: () => ({
        errorAuthTextApi: "",
    }),
    computed: {
        chooseBorder(){
            let cssClass = null
            if(this.$root.appType('vtb')){
                cssClass = 'vtb-border-button'
            }else if(this.$root.appType('nordwind')) {
                cssClass = 'nordwind-border-button'
            }else if(this.$root.appType('cash') || this.$root.appType('auto')) {
                cssClass = 'cash-border-button'
            }else if(this.$root.appType('bankiros')) {
                cssClass = 'rectangle-button'
            }


            return cssClass
        },

    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_travelata-variables.scss';

.tooltip {
    position: absolute;
    padding: 16px;
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    background-color: #fff;
    bottom: 60px;
    z-index: 100;

    //@media (max-width: 1250px) {
    //    margin-bottom: 40px;
    //}
    //@media (max-width: 960px) {
    //    margin-bottom: 80px;
    //}
    //@media (max-width: 600px) {
    //    margin-bottom: 100px;
    //}
    //@media (max-width: 380px) {
    //    margin-bottom: 140px;
    //}
    //@media (max-width: 350px) {
    //    margin-bottom: 230px;
    //}

    .modal-icon {
        width: 24px !important;
        height: 24px !important;
    }
}

.cash-border-button, .auto-border-button {
    height: 42px !important;
}
.nordwind-border-button {
    height: 42px !important;
}

.travelata_sure-tooltip {
    position: absolute;
    padding: 16px;
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    background-color: #fff;
    bottom: 60px;
    z-index: 100;
    display: flex;
    flex-direction: column;

    &_title {
        color: #333 !important;
    }
    .modal-icon {
        width: 24px !important;
        height: 24px !important;
    }

    &_text {
        @include tr-text(14px);
        font-family: 'OpenSansR';
        color: #707070;
    }

    &_subtitle {
        @include tr-text();
        color: #333;
        font-family: 'OpenSansR';
    }

    &_buttons {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        max-width: 250px;
        gap: 10px;
        align-self: end;
        margin-top: 20px;

    }

    &_next-button {
        background-color: #EC933D !important;
        max-width: 128px;
        margin: 0 !important;
        //position: absolute;
        //bottom: 0;
        //right: 0;
        color: #fff !important;
        @include tr-text(12px, 800, 16px);
        font-family: 'OpenSansEB';
        border-radius: 8px;
    }

    &_pointer {
        @include tr-text(12px, 400, 16px);
        color: #707070;
        font-family: 'OpenSansR';
    }
}
</style>
