var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth"},[_c('div',{staticClass:"main-option-for-app"},[_c('h1',{staticClass:"mb-6",class:_vm.addClass('forms_title')},[_vm._v(_vm._s(!_vm.showRegisterCodeField ? "До получения денег осталось заполнить два поля!" : "Код из СМС")+" ")]),_c('p',{staticClass:"mb-6 text-left",class:_vm.$root.appType('travelata') ? 'travelata_auth_subtitle' : 'auth_subtitle'},[_vm._v(_vm._s(!_vm.showRegisterCodeField ? "" : `Введите код, отправленный на номер +${_vm.anketa.phone.valueWithMask()}`)+" ")])]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[(!_vm.showRegisterCodeField)?_c('FieldsCard',{staticClass:"mb-5",attrs:{"anketaNames":['birthdate'],"altCols":{
                    cols: 12,
                    xl: 12,
                    lg: 12,
                    sm: 12,
                    xs: 12,
                }}}):_vm._e(),(!_vm.showRegisterCodeField)?_c('FieldsCard',{attrs:{"anketaNames":['incomeMain']}}):_vm._e(),(_vm.showRegisterCodeField)?_c('FieldsCard',{class:!_vm.$root.appType('cash') && !_vm.$root.appType('auto') ? `register-code_primary ${_vm.$root.state.type()}` : '',attrs:{"anketaNames":['registerCode']}}):_vm._e(),(_vm.isSmsForm && _vm.showRegisterCodeField)?_c('div',{staticClass:"helper"},[_c('span',{staticClass:"helper-text",on:{"click":function($event){return _vm.changeTemplate(true)}}}),_c('span',{staticClass:"helper-text",class:`helper-text_${_vm.$root.state.type()}`,on:{"click":_vm.callSopd}},[_vm._v("Выслать код повторно ")])]):_vm._e(),_c('InfoTooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.showErrorTooltip && _vm.$root.appType('travelata')),expression:"showErrorTooltip && $root.appType('travelata')"}],on:{"close-tooltip":_vm.closeTooltip}}),_c('MainButton',{staticClass:"mt-6",attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.auth}},[_vm._v("Далее ")])],1),(_vm.errorAuthTextApi.status && _vm.errorAuthTextApi.text !== '')?_c('div',{staticClass:"auth_error"},[_c('p',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.errorAuthTextApi.text))])]):_vm._e(),(_vm.showErrorTooltip && !_vm.$root.appType('auto'))?_c('ErrorInfo',{staticClass:"mt-4"}):_vm._e(),_c('InfoTooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.showErrorTooltip && _vm.$root.appType('auto')),expression:"showErrorTooltip && $root.appType('auto')"}],on:{"close-tooltip":_vm.closeTooltip}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }