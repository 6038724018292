<template>
  <div class="tooltip">
    <div id="tooltip_content">
         <p>Реквизиты счета банковской карты указаны в вашем интернет-банке, банковском договоре, или доступны в при обращении в банк, выпустивший карту.</p>
         <p>Например:</p>
         <img src="../../assets/img/popupimg.png">
         <p>Обратите внимание! Банковский счет должен принадлежать клиенту Paylate. В ином случае платеж не пройдет.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  data: () => ({}),
  props: {
  },
}
</script>

<style scoped>

</style>