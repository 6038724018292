<template>
    <div class="main-option-for-app" >
            <div class="information-toggle">
                <div
                    :style="movingButtonStyles"
                    :class="addClasses"
                    class="menu-toggle-active"
                    >
                </div>
                <div
                    v-for="option in filteredByExeption"
                    :key="option.value"
                    class="point-menu"
                    :class="{ 'active-point': option.status }"
                    
                    @click="
                        $emit('toogle-payent', option.value)
                        anketa.receiveOnCard.value = false;
                    "
                    >
                    <p>{{ option.text }}</p>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        props: {
            options: Array,
            //limit - число на калькуляторе при котором меняется отображение форм
            limit: Number
        },
        data(){
            return {}
        },
        computed: {
            anketa() {
                return this.$root.anketa;
            },
            payment: {
                get() {
                    return this.anketa.conditions.initialPayment;
                },
            },

            
            creditInfo() {
                // console.log("creditInformation", this.$root.anketa.creditInformation);
                let credits = this.anketa.creditInformation;
                return credits;
            },
            addClasses(){
                
                return {left: this.filteredByExeption[0].status,
                        center: this.filteredByExeption.length === 3 ? this.filteredByExeption[1].status : false,
                        'right-2': this.filteredByExeption.length === 2 ? this.filteredByExeption[1].status : false,
                        'right-3': this.filteredByExeption.length === 3 ? this.filteredByExeption[2].status : false,
                    }
            },

            movingButtonStyles(){
                return {
                    width: 100 / this.filteredByExeption.length + '%',   
                }
            },

            filteredByExeption(){
                const filtered = this.options.filter(item => !item.exception)
                if(this.creditInfoWithoutSpace >= this.limit){
                    return filtered
                }else {
                    return this.options
                }
            },

            creditInfoWithoutSpace(){
                let value = null
                if(this.creditInfo.requestedSumm === undefined){
                    value = '0'
                }else {
                    value = this.creditInfo.requestedSumm

                }
                
                value = value.replaceAll(/\s+/g, '')
                value = parseInt(value)
                return value
            }
        },

        
    }
</script>

<style lang="scss" scoped>
.information {
    &.show {
        display: block !important;
    }

    &-toggle {
        background: #eaeaea;
        border: 0.5px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 16px;
        width: 100%;
        height: 34px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin: 0px auto 32px auto;
        position: relative;
        cursor: pointer;
        

        > div.point-menu {
            color: #848485;
            letter-spacing: -0.08px !important;
            z-index: 2;
            height: 100%;

            display: flex;
            align-items: center;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            justify-content: center;
            width: 50%;

            p {
                // @extend %typo-p-sf-12 !optional;
                display: flex;
                align-items: center;
                text-align: center;
                margin: 0px 0px;
                font-family: Roboto !important;
                // color: #848485;

                font-family: SFPro;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.08px;
            }
        }

        .active-point {
            p {
                color: #0a0a0d;
            }
        }

        .menu-toggle-active {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 0;
            background: #ffffff;
            /* Button/Switcher */
            // box-shadow: 0px 2px 4px 1px rgba(49, 49, 49, 0.08);
            border-radius: 14px;
            border: 3px solid #eaeaea;
            transition: all 0.4s ease-in-out;
            z-index: 0;

            
            &.center {
                left: 50%;
                transform: translateX(-50%);
            }
            
            &.left {
                left: 0;
            }
            // &.right {
            //     left: 67%;
            //     // width: calc(100% / 3);
            // }

            &.right-2{
                left: 50%;
            }
            &.right-3{
                left: 67%;
            }
        }
    }
}
</style>