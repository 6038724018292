export const otherAccordions = [
    {
        id: 1,
        action: false,
        itemPanel: 'Как можно вернуть проценты по кредиту*?',
        bodyPanel: 'Совершите ежемесячные платежи, кроме последнего в соответствии с графиком и в срок, установленный условиями акции.  \n' +
            'После списания предпоследнего платежа в течение 14 дней направьте письмо на адрес: info@paylate.info, с номером лицевого счета/аккаунта и словами «Акция «Заём 0%» в теме письма. \n' +
            'В течение 7-ми дней сумма начисленных и уплаченных процентов за весь период пользования заёмных средств поступит на баланс вашего лицевого счета.\n' +
            'Зачисленная сумма может быть использована для будущих покупок через сервис «Paylate – Доверительная оплата» или перечислена на ваш счёт в банке РФ. \n' +
            `Полные условия акции <a target="_blank" href="./docs/loan0.pdf">тут</a>.`
    },
    {
        id: 2,
        action:true,
        itemPanel: 'Как быстро я получу деньги?',
        bodyPanel: 'Мы отправим деньги после подписания договора:\n' +
            '- переводы через СБП (по номеру телефона) осуществляются в течение нескольких минут;\n' +
            '- переводы на банковскую карту могут занимать до нескольких часов;\n' +
            '- переводы по банковским реквизитам могут занять до 24 часов, но как правило операции проходят гораздо быстрее.'
    },
    {
        id: 3,
        action:true,
        itemPanel: 'Какой размер ставки по кредиту*?',
        bodyPanel: 'ПСК для нецелевого займа может находиться в диапазоне от 0% до 292%. Процентная ставка за использование заемных средств будет рассчитываться индивидуально, исходя из кредитных показателей клиента, и составлять от 1,9% в месяц от суммы первоначального займа. После подачи заявки система определит точную процентную ставку.'
    },
    {
        id: 4,
        action:true,
        itemPanel: 'На какие цели я могу взять деньги?',
        bodyPanel: 'Средства предоставляются на любые цели, используйте их на ваше усмотрение.'
    },
    {
        id: 5,
        action:true,
        itemPanel: 'Способы погашения кредита*',
        bodyPanel: `- банковской картой: VISA, MasterCard, Мир;
                    - электронный кошелек: терминалы Элекснет/online кабинет;
                    - наличными без комиссии: в банкоматах и в отделениях банков.
                  `
    },
    {
        id: 6,
        action:true,
        itemPanel: 'Можно ли погасить кредит* досрочно?',
        bodyPanel: 'Да, вы можете досрочно погасить всю сумму на любом этапе. При полном погашении кредита<sup class="other">*</sup> в льготный период проценты не оплачиваются или оплачиваются по льготной процентной ставке, в остальных случаях переплата взимается за фактический срок пользования кредита<sup class="other">*</sup> .'
    },
]
export const vtbAccordions = [
    {
        id: 1,
        action: false,
        itemPanel: 'Как вернуть проценты по кредиту*?',
        bodyPanel: 'Возврат процентов по кредиту возможен по договорам со сроком 3, 6, 10 или 12 мес. \n' +
            'Совершите ежемесячные платежи, кроме последнего в соответствии с графиком и в срок, установленный условиями акции.  \n' +
            'После списания предпоследнего платежа в течение 14 дней направьте письмо на адрес: info@paylate.info, с номером лицевого счета/аккаунта и словами «Акция «Заём 0%» в теме письма. \n' +
            'В течение 7-ми дней сумма начисленных и уплаченных процентов за весь период пользования заёмных средств поступит на баланс вашего лицевого счета.\n' +
            'Зачисленная сумма может быть использована для будущих покупок через сервис «Paylate – Доверительная оплата» или перечислена на ваш счёт в банке РФ. \n' +
            `Полные условия акции <a class="vtb-accordion_link"  target="_blank" href="./docs/loan0.pdf">тут</a>.`
    },
    {
        id: 2,
        action:true,
        itemPanel: 'Как быстро я получу деньги?',
        bodyPanel: 'Мы отправим деньги после подписания договора:\n' +
            '- переводы через СБП (по номеру телефона) осуществляются в течение нескольких минут;\n' +
            '- переводы на банковскую карту могут занимать до нескольких часов;\n' +
            '- переводы по банковским реквизитам могут занять до 24 часов, но как правило операции проходят гораздо быстрее.'
    },
    {
        id: 3,
        action:true,
        itemPanel: 'Какой размер ставки по кредиту*?',
        bodyPanel: 'Процентная ставка за использование заемных средств будет рассчитываться индивидуально, исходя из кредитных показателей клиента, и составлять от 1,9% в месяц от суммы первоначального займа. После подачи заявки система определит точную процентную ставку. Полная стоимость кредита* для нецелевого займа может находиться в диапазоне от 0% до 292%.'
    },
    {
        id: 4,
        action:true,
        itemPanel: 'На какие цели я могу потратить деньги?',
        bodyPanel: 'Средства предоставляются на любые цели, используйте их на ваше усмотрение.'
    },
    {
        id: 5,
        action:true,
        itemPanel: 'Способы погашения кредита*',
        bodyPanel: `- банковской картой: VISA, MasterCard, Мир;
                    - электронный кошелек: терминалы Элекснет/online кабинет;
                    - наличными без комиссии: в банкоматах и в отделениях банков.
                  `
    },
    {
        id: 6,
        action:true,
        itemPanel: 'Можно ли погасить кредит* досрочно?',
        bodyPanel: 'Да, вы можете досрочно погасить всю сумму на любом этапе. При полном погашении кредита<sup class="other">*</sup> в льготный период проценты не оплачиваются или оплачиваются по льготной процентной ставке, в остальных случаях переплата взимается за фактический срок пользования кредита<sup class="other">*</sup> .'
    },
]
export const southwindAccordion = [
    {
        id: 1,
        action:true,
        itemPanel: 'Как вернуть проценты по кредиту*?',
        bodyPanel: 'Совершите ежемесячные платежи, кроме последнего в соответствии с графиком и в срок, установленный условиями акции.\n' +
            '\n' +
            'После списания предпоследнего платежа в течение 14 дней направьте письмо на адрес: info@paylate.info, с номером лицевого счета/аккаунта и словами «Акция «Заём 0%» в теме письма.\n' +
            '\n' +
            'В течение 7-ми дней сумма начисленных и уплаченных процентов за весь период пользования заёмных средств поступит на баланс вашего лицевого счета.\n' +
            '\n' +
            'Зачисленная сумма может быть использована для будущих покупок через сервис «Paylate – Доверительная оплата» или перечислена на ваш счёт в банке РФ.\n' +
            '\n' +
            `Полные условия акции <a class="vtb-accordion_link"  target="_blank" href="./docs/loan0.pdf">тут</a>.`
    },
    {
        id: 2,
        action:true,
        itemPanel: 'Как быстро я получу деньги?',
        bodyPanel: `<ul>Мы отправим деньги после подписания договора:
                        <li>- переводы через СБП (по номеру телефона) осуществляются в течение нескольких минут;</li>
                        <li>- переводы на банковскую карту могут занимать до нескольких часов;</li>
                        <li>- переводы по банковским реквизитам могут занять до 24 часов, но как правило операции проходят гораздо быстрее.</li>
                    </ul>`
    },
    {
        id: 3,
        action:true,
        itemPanel: 'Какой размер ставки по кредиту*?',
        bodyPanel: 'ПСК для нецелевого займа может находиться в диапазоне от 0% до 292%. Процентная ставка за использование заемных средств будет рассчитываться индивидуально, исходя из кредитных показателей клиента, и составлять от 1,9% в месяц от суммы первоначального займа. После подачи заявки система определит точную процентную ставку.'
    },
    {
        id: 4,
        action:true,
        itemPanel: 'На какие цели я могу потратить деньги? ',
        bodyPanel: 'Средства предоставляются на любые цели, используйте их на ваше усмотрение.'
    },
    {
        id: 5,
        action:true,
        itemPanel: 'Способы погашения кредита*',
        bodyPanel: `- банковской картой: VISA, MasterCard, Мир;
                    - электронный кошелек: терминалы Элекснет/online кабинет;
                    - наличными без комиссии: в банкоматах и в отделениях банков.
                  `
    },
    {
        id: 6,
        action:true,
        itemPanel: 'Можно ли погасить кредит* досрочно?',
        bodyPanel: 'Да, вы можете досрочно погасить всю сумму на любом этапе. При полном погашении кредита* в льготный период проценты не оплачиваются или оплачиваются по льготной процентной ставке, в остальных случаях переплата взимается за фактический срок пользования кредита*.'
    },
]
