<template>
    <div>
        <label class="text-size-small mb-1">
            {{ label }}: {{ fieldValue }} {{ appendText }}
        </label>
    </div>
</template>

<script>

export default {
    props: {
        value: [Number, String],
        label: String,
        append: String,
        isMoney: Boolean,
    },
    computed: {
        fieldValue() {
            if (this.isMoney && this.value) {
                return this.value.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');    
            } else {
                return this.value;
            }    
        },
        appendText() {
            return this.append;
        },    
    },
    
}
</script>