<template>
    <div class="block-4-body" id="cashback">
        <div class="block-4-body" id="cashback">
            <h1>Как получить кешбэк</h1>
            <div class="discount-info">
                <div v-if="urlParams('ac')" class="discount-info-item discount-item-1">
                    <div class="discount-info-block">
                        <img
                            class="discount-image"
                            src="@/assets/img/period-toggle.svg"
                        >
                    </div>
                    <p class="discount-info-text">Срок займа 12 месяцев
                    </p>
                </div>
                <div v-if="urlParams('acnew')" class="discount-info-item discount-item-1">
                    <div class="discount-info-block">
                        <img
                            class="discount-image"
                            src="@/assets/img/period-toggle-acnew.svg"
                        >
                    </div>
                    <p class="discount-info-text">Срок кредита<sup class="other">*</sup>  от 3х месяцев
                    </p>
                </div>
                <div class="discount-info-item discount-item-2">
                    <div class="discount-info-block">
                        <img
                            class="discount-image"
                            src="@/assets/img/days-counter.svg"
                        >
                    </div>
                    <p class="discount-info-text">Ежемесячно погашайте не позднее чем за 10 дней до даты платежа
                    </p>
                </div>
                <div class="discount-info-item discount-item-3">
                    <div class="discount-info-block">
                        <img
                            class="icon-image"
                            src="@/assets/img/icon8.svg"
                        >
                    </div>
                    <p
                        v-if="urlParams('ac')"
                        class="discount-info-text">Верните все уплаченные проценты кешбэком через 11 месяцев
                    </p>
                    <p
                        v-if="urlParams('acnew')"
                        class="discount-info-text">Верните все уплаченные проценты кешбэком
                    </p>
                </div>
            </div>
            </div>
        <v-btn
            class=" modal-open discount-button button_center rectangle-button"
            data-modal="form-modal"
            @click="$vuetify.goTo('#widget', 500)"
            >Получить деньги
        </v-btn>
        <a
            v-if="urlParams('ac')"
            class="discount-terms"
            href="./docs/index.pdf"
            target="_blank"
            >Полные условия акции
        </a>
        <a
            v-if="urlParams('acnew')"
            class="discount-terms"
            href="./docs/loan0.pdf"
            target="_blank"
            >Полные условия акции
        </a>
    </div>
</template>

<script>
export default {
    name: "BlockHTML4Cash.vue",
    methods: {
        urlParams(param){
            const urlLocation = location.search.split('&')
            return urlLocation.includes(param)
        },
    }
}
</script>

<style scoped lang="scss">

</style>
