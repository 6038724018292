<template>
    <label class="checkbox is-size-4">
        <input
            v-model="fieldValue" 
            type="checkbox"
            class="checkbox" 
            autocomplete="off" 
            />

      <span class="checkmark"></span>
      {{label}}
    </label>
</template>

<script>
export default {
    props: {
        value: Boolean,
        label: String,
    },
    computed: {
        fieldValue: {
            get() {
                return this.value;
            },
            set( val ) {
                this.$emit('input', val);
            }
        },

        bgCondition(){
            return this.$vuetify.theme.themes.light.primary
        }
    },

}
</script>

<style lang="scss" scoped>
.checkbox {
        display: flex;
        text-align: right;
        align-items: center;
        user-select: none;

        .checkmark {
            position: relative;
            flex: 0 0 12px;
            width: 12px;
            height: 12px;
            border: 1px solid #cccccc;
            border-radius: 4px;
            transition: 0.2s;
            margin-right: 6px;

            &:before {
                content: "";
                left: 3px;
                transition: 0.2s;
                position: absolute;
                top: 3px;
                border-radius: 1px;
                width: 8.1px;
                height: 4px;
            }
        }

        input {
            display: none;
        }

        input:checked + .checkmark {
            background-color: v-bind(bgCondition);
            border: none;

            &:before {
                border-left: 2px solid #fff;
                border-bottom: 2px solid #fff;
                transform: rotate(-45deg);
            }
        }

        &:hover + .checkmark {
            background-color: rgba(246, 59, 102, 0.2);
        }
    }
</style>