<template>
  <div>
    <v-chip :text-color="loadState.color">
      <div>
        <!-- <v-progress-circular v-if="loadState.isLoading" indeterminate size="16" width="2" /> -->
        <!-- <v-icon v-else :color="loadState.color">mdi-file</v-icon> -->
        <span>{{ titleText }}</span>
      </div>
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    state: {
      type: String,
      default: 'waiting'
    },
    message: String,
  },
  data: () => ({
    states: {
      waiting: { color: 'pl-black', isLoading: true },
      success: { color: 'green' },
      error: { color: 'red' },
    },
  }),
  computed: {

    titleText() {
      return this.title;
    },
    loadState() {
      // const style = this.style;
      return this.states[this.state];
    }
  },
  methods: {

  }
}
</script>