<template>
    <div>
        <BankirosDeclined v-if="$root.appType('bankiros')"/>
        <SouthwindDeclined v-if="$root.appType(['southwind', 'nordwind'])"/>
        <TravelataDeclined v-if="$root.appType('travelata')"/>
        <CashDeclined v-if="$root.appType('cash')"/>
        <VtbDeclined v-if="$root.appType('vtb')"/>
<!--        <div class="main-option-for-app">-->
<!--            <h1-->
<!--                :class="$root.appType('travelata') ? 'travelata_declined' : ''"-->
<!--                >Заявка отклонена</h1>-->
<!--            <p class="status-img mt-8">-->
<!--                <img src="../../assets/img/pngegg.png">-->
<!--            </p>-->
<!--        </div>-->

    </div>
</template>

<script>
import BankirosDeclined from "@/components/bankiros/modal/Declined.vue";
import SouthwindDeclined from "@/components/southwind/controls/Modals/SouthwindDeclined.vue";
import TravelataDeclined from "@/components/travelata/TravelataDeclined.vue";
import CashDeclined from "@/components/cash/CashDeclined.vue";
import VtbDeclined from "@/components/vtb/VtbDeclined.vue";

export default {
    name: "Declined",
    components: {
        BankirosDeclined,
        SouthwindDeclined,
        TravelataDeclined,
        CashDeclined,
        VtbDeclined
    },
    computed: {

    },

    mounted() {
        this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("reject");
        this.$root.mindbox("Получил отказ по заявке");
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_travelata-variables.scss';

.travelata {
    &_declined {
        @include tr-text(24px, 800, 28px);
        font-family: 'OpenSansEB';
        color: #333;
    }
}
</style>
