<template>
    <div :key='componentKey' class="nordwind-template-slider">
        <div class='block-2' style="background: none;">
                
            <div class='container-width '>
                <div class='block-2-body nordwind_container'>
                    <div class='block-2-announce'>
                        <div data-popmechanic-embed="72227"></div> 
                        <div class='container-info sliders sliders-position' id='widget' >
                            <Slider
                                v-model='anketa.initialPayment.value'
                                v-bind='anketa.initialPayment'
                                :nameSlider='sliderinitialPayment'
                                :min='MinSum'
                                :max='MaxSum'
                                :step='1000'
                                :sliderColors="{bg:'#AB292D', track: 'grey'}"
                                @input='anketa.initialPayment.input'
                                >
                                <div class='container-info-limit slider-summ' slot='labelSliderSumm'>
                                    <p class='container-info-limit_description container-info-limit_description__ticks'
                                        >{{ minSumForDown }} &#8381;
                                    </p>
                                    <p class='container-info-limit_description container-info-limit_description__ticks'>
                                        {{ maxSumForDown }}
                                        <span>&#8381;</span>
                                    </p>
                                </div>
                            </Slider>
                            <Slider
                                v-model='anketa.term.value'
                                v-bind='anketa.term'
                                :tick-size='sliderPeriodSTR.length'
                                :slider-array='sliderPeriod'
                                :tick-labels='sliderPeriodSTR'
                                :nameSlider='sliderTerm'
                                :min='1'
                                :max='sliderPeriod.length'
                                :step='1'
                                :sliderColors="{bg:'#AB292D', track: 'grey'}"
                                @input='anketa.term.input'
                                :metricGoalName="'slider_term'"
                            />
                            <Condition v-model='anketa.conditions.monthlyPayment'>Ежемесячный платеж</Condition>
                            <p></p>
                            <div v-if='anketa.actionInfo && params === "ac"' class='container-info-cashback'>
                                <div class='container-info-cashback-title'>
                                    <h1>
                                        Ваш кешбэк: {{ anketa.term.valueTerm === 12 ? cashback : "0" }}
                                        <b class='ruble-icon'>&#8381;</b>
                                    </h1>
                                </div>
                                    <p>
                                        {{
                                            anketa.term.valueTerm === 12 ?
                                                "":
                                                "Чтобы получить кешбэк, выберите срок 12 месяцев"
                                        }}
                                    </p>
                                    <p class="pointer more" @click="$vuetify.goTo('#cashback', 500)">
                                        Подробнее
                                    </p>
                            </div>
                            <div v-if='anketa.actionInfo && params === "acnew"' class='container-info-cashback'>
                                <div class='container-info-cashback-title'>
                                    <h1>
                                        <!-- Кешбэк {{ anketa.term.valueTerm === 12 ? cashback : "0" }} -->
                                        Ваш кешбэк: {{ anketa.term.valueTerm === 1 ? "0" : cashback }}
                                        <b class='ruble-icon'>&#8381;</b>
                                    </h1>
                                </div>
                                <p>
                                    {{anketa.term.valueTerm === 1 ? "Чтобы получить кешбэк, выберите срок более 1 месяца": ""}}
                                </p>
                                <p class="pointer more" @click="$vuetify.goTo('#cashback', 500)">
                                    Подробнее
                                </p>
                            </div>
                            <v-btn
                                class='nordwind_main-button modal-open'
                                data-modal='form-modal'
                                id='get-money'
                                @click='changeStatusModal()'
                                >
                                Получить
                                <span id='button-value-amount'>
                                {{ anketa.conditions.initialPayment.toLocaleString().replace(/,/, " ") }}
                                <b class='nordwind_ruble-icon'>&#8381;</b>
                                </span>
                            </v-btn>
                        </div>
                        <div
                            v-show="!showSliderComplited.status && !showSliderComplited.error.status"
                            class='preloader'
                            >
                            <img class src="../assets/img/preloader-two.gif"/>
                        </div>
                        <div
                            v-show='showSliderComplited.error.status'
                            class="error-text"
                            >
                            <p>{{ showSliderComplited.error.text }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from "@/components/Slider";
import Condition from "@/components/Condition.vue";
import {moneyFormatWithSpace} from "@/components/helpers/MoneyFormat.js";

export default {
    name: "NordWindTemplateSlider",
    components: {Slider, Condition},
    props: {
        countPeriod: Number,
        reRenderComponent: Boolean,
    },
    data: () => ({
        componentKey: 0, //для принудительного повторного рендинга компонента
        showSliderComplited: {
            status: false,
            error: {
                status: false,
                text: "Системная ошибка",
            },
        },
        MinSum: 3000,
        MaxSum: 60000,
        sliderPeriod: [1],
        sliderPeriodSTR: ["1"],
        sliderinitialPayment: {
            name: "Сумма",
            unit: "&#8381;",
            useModel: true,
        },
        sliderTerm: {
            name: "Срок",
            unit: "мес.",
            useModel: false,
        },
        params: ''

    }),
    watch: {
        reRenderComponent: function () {
            this.forceRerender();
        },
    },
    methods: {
        changeStatusModal() {
            this.$root.metrika96799237.reachGoal("get_money");
            this.$root.mindbox("Выбрал сумму на калькуляторе");

            this.$root.anketa.isOpenModal = true;
        },
        forceRerender() {
            this.componentKey += 1;
        },
        limitSumDefinitions(urlParams) {
            if(urlParams.get("e")) this.$root.mindbox("Посетил страницу. Лимит 15k");
            if(urlParams.get("w")) this.$root.mindbox("Посетил страницу. Лимит 30k");
            if(urlParams.get("o")) this.$root.mindbox("Посетил страницу. Лимит 50k");
            if(urlParams.get("h")) this.$root.mindbox("Посетил страницу. Лимит 80k");
            if(urlParams.get("t")) this.$root.mindbox("Посетил страницу. Лимит 100k");
            if(urlParams.get("m")) this.$root.mindbox("Посетил страницу. Лимит 150k");
            if(urlParams.get("r")) this.$root.mindbox("Посетил страницу. Лимит 100k. NearPrime");
            if(urlParams.get("y")) this.$root.mindbox("Посетил страницу. Лимит 500k. NearPrime");
            if(urlParams.get("nr")) this.$root.mindbox("Посетил страницу. Лимит 20к. Нерезидент");
        },
        getFirstDate(urlParams) {
            this.connector
                .isMainInformationCalc(urlParams.get("s"))
                .then((response) => {
                    if (response.success && response.slider_params.maxSum !== 0) {
                        this.showSliderComplited.status = true;
                        this.anketa.firstDateSlider = this.getObjSort(response.slider_params.listStocks);

                        if (urlParams.get("ac") !== null) {
                            this.anketa.actionInfo = true;
                            this.params = 'ac'
                        }

                        else if (urlParams.get("acnew") !== null) {
                            this.anketa.actionInfo = true
                            this.params = 'acnew'
                        }
                        for (let k in this.anketa.firstDateSlider) {
                            this.anketa.firstDateSlider[k].periodStr = String(this.anketa.firstDateSlider[k].period);
                        }
                        this.sliderPeriod = this.getsliderPeriod(this.anketa.firstDateSlider);

                        this.sliderPeriodSTR = this.getsliderPeriodSTR(this.anketa.firstDateSlider);
                        this.MaxSum = response.slider_params.maxSum;
                        this.MinSum = response.slider_params.minSum;

                        if (urlParams.get("sum")) {
                            this.anketa.initialPayment.value = +urlParams.get("sum") * 1000;
                        } else {
                            this.anketa.initialPayment.value = response.slider_params.maxSum;
                        }

                        for (let i in this.sliderPeriod) {
                            if (this.sliderPeriod[i] == response.slider_params.defaultPeriod) {
                                this.anketa.term.value = parseInt(i) + 1;
                            }
                        }
                        this.anketa.conditions.calculate(this.anketa.initialPayment.value, this.anketa.term.value);
                        return this.anketa.firstDateSlider;
                    } else {
                        this.anketa.sliderParams = false;
                    }
                })
                .catch((error) => {
                    this.showSliderComplited = {
                        status: false,
                        error: {
                            status: true,
                            text: error,
                        },
                    };
                });
        },
        getObjSort(obj) {
            return obj.sort((a, b) => (a.period > b.period ? 1 : -1));
        },
        getsliderPeriod(obj) {
            let array = [];
            for (let k in obj) {
                array.push(obj[k].period);
            }
            return array;
        },
        getsliderPeriodSTR(obj) {
            let array = [];
            for (let k in obj) {
                array.push(obj[k].periodStr);
            }
            return array;
        },
    },

    computed: {
        anketa() {
            return this.$root.anketa;
        },
        connector() {
            return this.$root.connector;
        },
        cashback: {
            get() {
                return moneyFormatWithSpace((this.anketa.initialPayment.value / 100) * this.anketa.term.percent * (this.anketa.term.valueTerm - 1));
            },
        },

        minSumForDown() {
            return moneyFormatWithSpace(this.MinSum);
        },

        maxSumForDown() {
            return moneyFormatWithSpace(this.MaxSum);
        },

        minTerm: {
            get() {
                return Math.min.apply(null, this.sliderPeriod);
            },
        },
        maxTerm: {
            get() {
                return Math.max.apply(null, this.sliderPeriod);
            },
        },
        templateText: {
            set() {
                return this.anketa.conditions.initialPayment > 40000 ? false : true;
            },
            get() {
                return this.anketa.conditions.initialPayment > 40000 ? false : true;
            },
        },

        getUrlParams() {
          let urlParams = new URLSearchParams(window.location.search);
          console.log(urlParams)
          return urlParams.has('s');
        },
    },
    created() {
        let urlParams = new URLSearchParams(window.location.search);
        this.anketa.mindboxId = urlParams.get("c");
        this.getFirstDate(urlParams);
        this.limitSumDefinitions(urlParams);
    },
};
</script>

<style lang="scss" scoped>
// @import "@/assets/scss/_variables.scss";

.nordwind {
    &_container {
        width: 100%;
    }

    &_main-button {
    background: var(--v-primary-base) !important;
    text-transform: initial !important;
    border-radius: 12px;
    line-height: 59px !important;
    height: 64px !important;
    font-family: NerisBlack !important;
    font-style: normal;
    font-weight: 900;
    font-size: 20px !important;
    color: #ffffff !important;
    text-align: center;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
    max-width: 320px !important;
    width: 100%;
    margin: 0 auto;
    cursor: pointer;
    letter-spacing: 1px;

    // &:hover {
    //     background: $color-8;
    // }

    // &:focus {
    //     background: $color-8;
    // }

    &.active {
        background-color: #26a212 !important;
    }

   
    }

    &_ruble-icon {
        font-family: SFPro, sans-serif;
        font-size: 20px;
    }
}

.container-info {
    width: 100%;
}

.nordwind-template-slider {
    max-width: 500px;
    width: 100%;
}

.more {
    color: #AB292D!important;
    font-weight: 500!important;
}

</style>
