let debug = false;

// И проверять чтобы стороны картинки были мин 700пх, 
// но файл весил не более 5-6мб, если больше - то ужимать

export function prepare( file ) {
    var imgSrc = URL.createObjectURL(file);
    const img = new Image();

    var canvas = document.createElement("canvas");
    var context = canvas.getContext('2d');   

    return new Promise( (resolve/*, reject*/) => {

      img.onload = () => {
        var blob;
        var maxSize = 5 * 1024 * 1024;
        var scaleSize = 1000;
        var minResolution = 700;
        var scaleIndex;
        var scaledWidth;
        var scaledHeight;
        var toSmallResolution = false;

        if (debug) console.log('Image loaded', img);

        if (img.naturalHeight < minResolution || img.naturalWidth < minResolution) {
          if (debug) {
            console.log('Image rejected by size', img);
            console.log('height:', img.naturalHeight);
            console.log('width:', img.naturalWidth);

          }
          // reject( 'to small resolution' );
          toSmallResolution = true;
        }

        if (debug) {
          console.log( 'file.size', file.size, file.size > maxSize);
          console.log( 'toSmallResolution', toSmallResolution);
        }

        if ( file.size > maxSize || toSmallResolution) {

          if (img.naturalWidth > img.naturalHeight) {
            scaleIndex = scaleSize / img.naturalHeight;
          } else {
            scaleIndex = scaleSize / img.naturalWidth;
          }

          scaledWidth = img.naturalWidth * scaleIndex;
          scaledHeight = img.naturalHeight * scaleIndex;

          if (debug) {
            console.log('height:', img.naturalHeight);
            console.log('width:', img.naturalWidth);
            console.log('scaled > s, w, h', scaleIndex, scaledWidth, scaledHeight);
          }

          canvas.width = scaledWidth;
          canvas.height = scaledHeight;

          context.drawImage(img, 0, 0, scaledWidth, scaledHeight);

          blob = dataURLtoBlob(canvas.toDataURL("image/jpeg", 1));

          if (debug) console.log( 'scaled > blob.size', blob);

          resolve( blob );
        } else {

          resolve( file );
        }
      }

      img.src = imgSrc;
    });
}

export function scaleImage( file, scaledWidth0 ) {
    var imgSrc = URL.createObjectURL(file);
    const img = new Image();

    var canvas = document.createElement("canvas");
    var context = canvas.getContext('2d');

    img.onload = () => {
      var scale = scaledWidth0 / img.naturalWidth;

      var scaledWidth = img.naturalWidth * scale;
      var scaledHeight = img.naturalHeight * scale;

      canvas.width=scaledWidth;
      canvas.height=scaledHeight;

      context.drawImage(img, 0, 0, scaledWidth, scaledHeight);
      
      return dataURLtoBlob(canvas.toDataURL("image/jpeg", 1));

    }
    img.src = imgSrc;
}

function dataURLtoBlob(dataURI) {
  if (debug) console.log( 'dataURLtoBlob()');
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

export function makePreview(file, previewSize) {
  var imgSrc = URL.createObjectURL(file);
  const img = new Image();

  var canvas = document.createElement("canvas");
  var context = canvas.getContext('2d');   

  return new Promise( (resolve/*, reject*/) => {

    img.onload = () => {
      var scaleIndex;
      var scaledWidth;
      var scaledHeight;
      
      if (img.naturalWidth > img.naturalHeight) {
        scaleIndex = previewSize.width / img.naturalWidth;
      } else {
        scaleIndex = previewSize.height / img.naturalHeight;
      }

      scaledWidth = img.naturalWidth * scaleIndex;
      scaledHeight = img.naturalHeight * scaleIndex;

      if (debug) {
        console.log('width:', img.naturalWidth, previewSize.width);
        console.log('height:', img.naturalHeight, previewSize.height);
        console.log('scaled > s, w, h', scaleIndex, scaledWidth, scaledHeight);
      }

      canvas.width = scaledWidth;
      canvas.height = scaledHeight;

      context.drawImage(img, 0, 0, scaledWidth, scaledHeight);

      resolve( canvas.toDataURL("image/jpeg", 1) );
    }

    img.src = imgSrc;
  });
}

export function createPhotoOnBase64(input) {
  return new Promise( 
    (resolve, reject) => {
      if (input.files && input.files[0]) {
        console.log(input.files[0]);
        var reader = new FileReader();

        reader.onload = function (e) {
          var b64Data = e.target.result.split(',');

          resolve(b64Data[1]);
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        reject();
      }
    }
  )
}