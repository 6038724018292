<template>
  <div>
    <div v-if="title">{{ title }}</div>

    <slot name="header"></slot>
    <slot>
      <div>
        <v-row >
          <Field
            v-for="name in anketaNames"
            :key="name"
            :anketaName="name"
            :outlined="color == 'white'"
            :altCols="altCols"
          />
        </v-row>

      </div>
    </slot>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import Field from "@/components/Field";

export default {
  components: {Field},
  props: {
    title: String,
    anketaNames: Array,
    color: {
      type: String,
      default: "#f0f0f2"
    },
    altCols: Object
  }
}
</script>

<style lang="scss" scoped>
.w200 {
    width: 200px !important;
}
</style>
