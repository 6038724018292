let serviceFunctions = {
    // Прокрутка к элементу
    // position: "start", "center", "end"
    scrollFrameTo: function (position) {
        /*console.log(widget)*/
        parent.postMessage({
            event_id: 'scrollFrameTo',
            position,
        }, '*');
    },
    // Получает дробную часть числа (копейки)
    getDecimal: function (num) {
        var decim = Math.round((num - Math.floor(num)) * 100);
        if (decim < 10) decim = "0" + decim;
        return decim;
    },
    // Получает целую часть числа (рубли)
    getInteger: function (num) {
        return Math.floor(num);
    },
    // Форматирует число по тысячам
    numberWithCommas: function (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    formatNumber: function (num) {
        return Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
    },
    //сопрягает числительное
    //var rubbles = declOfNum(['Рубль','Рубля','Рублей']);
    //rubbles(234); - return Рубля
    declOfNum: (function () {
        var cases = [2, 0, 1, 1, 1, 2];
        var declOfNumSubFunction = function (titles, number) {
            number = Math.abs(number);
            return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
        };
        return function (_titles) {
            if (arguments.length === 1) {
                return function (_number) {
                    return declOfNumSubFunction(_titles, _number)
                }
            } else {
                return declOfNumSubFunction.apply(null, arguments)
            }
        };
    })(),
    month: function (number) {
        return this.declOfNum(['месяц', 'месяца', 'месяцев'], number);
    },
    rubble: function (number) {
        return this.declOfNum(['рубль', 'рубля', 'рублей'], number);
    },
    year: function (number) {
        return this.declOfNum(['год', 'года', 'лет'], number);
    },
    day: function (number) {
        return this.declOfNum(['день', 'дня', 'дней'], number);
    },
   

    replaceToCyrilicKeymap: {
        '`': 'ё',
        'q': 'й', 'w': 'ц', 'e': 'у', 'r': 'к', 't': 'е', 'y': 'н', 'u': 'г', 'i': 'ш', 'o': 'щ', 'p': 'з', '[': 'х', ']': 'ъ',
        'a': 'ф', 's': 'ы', 'd': 'в', 'f': 'а', 'g': 'п', 'h': 'р', 'j': 'о', 'k': 'л', 'l': 'д', ';': 'ж',/* "'" : 'э',*/
        'z': 'я', 'x': 'ч', 'c': 'с', 'v': 'м', 'b': 'и', 'n': 'т', 'm': 'ь', /*',' : 'б', '.' : 'ю',*/
        '~': 'Ё',
        'Q': 'Й', 'W': 'Ц', 'E': 'У', 'R': 'К', 'T': 'Е', 'Y': 'Н', 'U': 'Г', 'I': 'Ш', 'O': 'Щ', 'P': 'З', '{': 'Х', '}': 'Ъ',
        'A': 'Ф', 'S': 'Ы', 'D': 'В', 'F': 'А', 'G': 'П', 'H': 'Р', 'J': 'О', 'K': 'Л', 'L': 'Д',/* ':' : 'Ж', '"' : 'Э',*/
        'Z': 'Я', 'X': 'Ч', 'C': 'С', 'V': 'М', 'B': 'И', 'N': 'Т', 'M': 'Ь', /*'<' : 'Б', '>' : 'Ю'*/
    },

    replaceToCyrilic: (key) => {
        let map = serviceFunctions.replaceToCyrilicKeymap;

        if (map[key]) {
            return map[key];
        } else {
            return ''
        }
    }
};

serviceFunctions.month = serviceFunctions.month.bind(serviceFunctions);
serviceFunctions.year = serviceFunctions.year.bind(serviceFunctions);
serviceFunctions.day = serviceFunctions.day.bind(serviceFunctions);
serviceFunctions.rubble = serviceFunctions.rubble.bind(serviceFunctions);

export default serviceFunctions.month;