import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import state from '../mixins/state';
// import state from '../mixins/state';
Vue.use(Vuetify);
// let mainColor = null
let typeOfApp = state.data().state.type()
console.log('typeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', typeOfApp)

let themes = {
    nordwind: {
        primary: '#AB292D',
        secondary: '#D6181E'
    },
    bankiros: {
        primary: '#95CE5A',
        black: '#333E50',
        grey: '#F1F4F7',
        grey2: '#848485',
        'dark-grey': '#9299A2',
        'light-grey': '#ADB4BF',
        anchor: '#388EC3',
        secondary: '#333E50'
    },
    vtb: {
        primary: '#003EFF',
        secondary: '#232229'
    },
    travelata: {
        primary: '#EC933D',
        secondary: '#EC933D'
    },
    cash: {
        primary: '#EB3B66'
    },
    auto: {
        primary: '#EB3B66'
    },
    southwind: {
        primary: '#AB292D',
        secondary: '#D6181E'
    }
}

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: themes[typeOfApp]
        },
    },
});
