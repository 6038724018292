<template>
    <!--BlockHTML6-->
    <div class="block-6">
        <div class="container-width">
                <div class="block-6-body">
                    <div class="step-info">
                        <div class="step-info-item step-item">

                            <div class="step-info-block">
                                <img
                                    v-if="!isNordwind"
                                    src="../../assets/img/1_step.svg"
                                    alt=""
                                >
                                <svg v-else width="46" height="71" viewBox="0 0 46 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.34 71C2.73444 71 0.931666 69.23 0.931666 65.69C0.931666 62.2811 2.73444 60.5767 6.34 60.5767H17.3533V16.0317L8.995 21.0467C7.42167 21.9644 5.97944 22.1939 4.66833 21.735C3.42278 21.2761 2.47222 20.4567 1.81667 19.2767C1.16111 18.0967 0.964444 16.8183 1.22667 15.4417C1.48889 13.9994 2.40667 12.8194 3.98 11.9017L17.55 3.74C18.8611 2.95333 20.1722 2.29778 21.4833 1.77333C22.7944 1.18333 24.0072 0.888331 25.1217 0.888331C26.4983 0.888331 27.6456 1.28167 28.5633 2.06833C29.4811 2.78944 29.94 4.00222 29.94 5.70667V60.5767H39.97C43.5756 60.5767 45.3783 62.2811 45.3783 65.69C45.3783 69.23 43.5756 71 39.97 71H6.34Z" fill="#D6181E"/>
                                </svg>
                            </div>

                            <p class="step-info-text">Кредит<sup class="other">*</sup> уже одобрен
                            </p>
                            <div class="arrow-img">
                                <img class="img_arrow"
                                    src="../../assets/img/step_arrow.svg"
                                    alt=""
                                >
                            </div>

                        </div>
                        <div class="step-info-item step-item">
                            <div class="step-info-block">
                                <img
                                    v-if="!isNordwind"
                                    src="../../assets/img/2_step.svg"
                                    alt=""
                                >
                                <svg v-else width="50" height="71" viewBox="0 0 50 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.07833 71C5.98056 71 4.44 70.5411 3.45667 69.6233C2.53889 68.64 2.08 67.2306 2.08 65.395C2.08 64.2806 2.375 63.1989 2.965 62.15C3.555 61.1011 4.40722 59.9867 5.52167 58.8067L25.2867 37.7633C28.3022 34.6167 30.4656 31.765 31.7767 29.2083C33.0878 26.5861 33.7433 23.9639 33.7433 21.3417C33.7433 14.655 29.7117 11.3117 21.6483 11.3117C19.4194 11.3117 17.1906 11.6394 14.9617 12.295C12.7328 12.885 10.4711 13.9339 8.17667 15.4417C6.73444 16.425 5.39056 16.72 4.145 16.3267C2.89944 15.9333 1.94889 15.1467 1.29333 13.9667C0.637778 12.7211 0.408333 11.41 0.605 10.0333C0.867222 8.65667 1.71944 7.47667 3.16167 6.49333C5.98056 4.59222 9.12722 3.15 12.6017 2.16667C16.1417 1.18333 19.6817 0.691667 23.2217 0.691667C30.6294 0.691667 36.3 2.36333 40.2333 5.70667C44.2322 9.05 46.2317 13.8356 46.2317 20.0633C46.2317 24.2589 45.2156 28.2906 43.1833 32.1583C41.2167 36.0261 38.1028 40.1889 33.8417 44.6467L18.6 60.5767H44.265C47.8706 60.5767 49.6733 62.2811 49.6733 65.69C49.6733 69.23 47.8706 71 44.265 71H8.07833Z" fill="#D6181E"/>
                                </svg>
                            </div>
                            <p class="step-info-text">Подтвердите по смс
                            </p>
                            <div class="arrow-img">
                                <img class="img_arrow"
                                    src="../../assets/img/step_arrow.svg"
                                    alt=""
                                >
                            </div>
                        </div>
                        <div class="step-info-item step-item">
                            <div class="step-info-block">
                                <img
                                    v-if="!isNordwind"
                                    src="../../assets/img/3_step.svg"
                                    alt=""
                                >
                                <svg v-else width="51" height="72" viewBox="0 0 51 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.1233 71.9833C21.5833 71.9833 17.9122 71.5244 14.11 70.6067C10.3078 69.6889 6.99722 68.3122 4.17833 66.4767C2.53944 65.3622 1.55611 64.1167 1.22833 62.74C0.900556 61.2978 1.06444 60.0194 1.72 58.905C2.37556 57.725 3.35889 56.9383 4.67 56.545C6.04667 56.1517 7.58722 56.4139 9.29167 57.3317C11.9794 58.7739 14.5689 59.8228 17.06 60.4783C19.5511 61.0683 22.1078 61.3633 24.73 61.3633C33.58 61.3633 38.005 57.8889 38.005 50.94C38.005 47.6622 36.8906 45.2367 34.6617 43.6633C32.4983 42.0244 29.3189 41.205 25.1233 41.205H18.83C16.9944 41.205 15.585 40.7461 14.6017 39.8283C13.6839 38.9106 13.225 37.6322 13.225 35.9933C13.225 34.3544 13.6839 33.0761 14.6017 32.1583C15.585 31.2406 16.9944 30.7817 18.83 30.7817H23.9433C27.6144 30.7817 30.5317 29.8967 32.695 28.1267C34.8583 26.2911 35.94 23.7672 35.94 20.555C35.94 17.5394 34.9239 15.245 32.8917 13.6717C30.925 12.0983 28.0406 11.3117 24.2383 11.3117C21.8128 11.3117 19.5183 11.6067 17.355 12.1967C15.2572 12.7211 12.93 13.7372 10.3733 15.245C8.86556 16.1628 7.48889 16.425 6.24333 16.0317C4.99778 15.5728 4.01444 14.7861 3.29333 13.6717C2.63778 12.4917 2.44111 11.2133 2.70333 9.83667C2.96556 8.46 3.88333 7.28 5.45667 6.29666C11.2256 2.56 17.8467 0.691667 25.32 0.691667C32.5311 0.691667 38.1361 2.33056 42.135 5.60833C46.1339 8.82055 48.1333 13.3111 48.1333 19.08C48.1333 22.8167 47.1828 26.0944 45.2817 28.9133C43.3806 31.7322 40.7911 33.7644 37.5133 35.01C41.5122 36.1244 44.6261 38.1894 46.855 41.205C49.0839 44.155 50.1983 47.7606 50.1983 52.0217C50.1983 58.1839 47.9694 63.0678 43.5117 66.6733C39.0539 70.2133 32.9244 71.9833 25.1233 71.9833Z" fill="#D6181E"/>
                                </svg>

                            </div>
                            <p class="step-info-text">Получите деньги на карту в течение 2 минут
                            </p>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BlockHTML6",
    computed: {
        isNordwind(){
            return this.$root.state.isNordwind()
        }
    }
}
</script>

<style scoped>

</style>
