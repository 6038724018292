<template>
  <!--footer-->
    <footer class="footer">
        <div class="container-width">
            <p
                :class="getFooterClasses"
                >
              <sup class="other">*</sup> Здесь и далее под «Кредит» понимается заём. Заём предоставляется микрокредитной компанией ООО "МКК "Кредит Лайн",
            регистрационный номер записи в государственном реестре микрофинансовых организаций
              2120150001955 в соответствии со свидетельством ФСФР России №0002195 от 14.08.2012. <sup class="other">**</sup> Информация в соответствии с <a target="_blank" href="https://кредит-лайн.рф/docs/Information_in_accordance_with_353-FZ.pdf">п. 4 ст. 5 ФЗ N 353-ФЗ</a>. <a target="_blank" href="https://paylate.ru/o-servise/">Информация о сервисе</a>, <a target="_blank" href="https://кредит-лайн.рф/">Официальный сайт займодателя</a>
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterHTML",
    data(){
        return {
            footerInfoClasses: [
                {
                    type: 'vtb',
                    class: 'vtb_laywer-information'
                },
                {
                    type: 'cash',
                    class: 'laywer-information'
                },
                {
                    type: 'auto',
                    class: 'laywer-information'
                },
                {
                    type: 'nordwind',
                    class: 'laywer-information'
                },
                {
                    type: 'travelata',
                    class: 'travelata_laywer-information'
                },
                {
                    type: 'southwind',
                    class: 'southwind_laywer-information'
                },
            ]
        }

    },
    computed: {
        getFooterClasses(){
            let searchingClass = null
            const type = this.$root.state.type()
            this.footerInfoClasses.forEach(item => {
                if(type === item.type){
                    searchingClass = item.class
                }
            })
            return searchingClass
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";
@import "@/assets/scss/_travelata-variables.scss";

.laywer-information {
  max-width: 736px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #f5f5f5;
  margin: 0 auto;
  padding: 50px 10px 100px 10px;
}

.vtb_laywer-information {
  @include small-text-middle;
  max-width: 736px;
  text-align: center;
  color: #F5F5F5;
  margin: 0 auto;
  padding: 50px 10px 100px 10px;

  & > a {
    color: #1976D2;
  }
}

.travelata_laywer-information {
    max-width: 736px;
    text-align: center;
    margin: 0 auto;
    padding: 50px 10px 100px 10px;
    @include tr-text();
    font-family: 'OpenSansR';
    color: #fff;
    @media (max-width: 600px) {
        padding: 32px 10px 64px 10px;
    }
    & > a {
        color: #059BCE;
        text-decoration: underline;
    }
}
.southwind_laywer-information {
    max-width: 736px;
    text-align: center;
    margin: 0 auto;
    padding: 50px 10px 100px 10px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: #BFBFC0;
    @media (max-width: 600px) {
        padding: 32px 10px 64px 10px;
    }
    & > a {
        color: #1976D2;
        text-decoration: underline;
    }
}
</style>
