<template>
    <div
        class="block-5 block-5_bg">
        <div class="container-width">
            <div class="block-5-body">
                <h1
                    class="block-5-body_title"
                >Частые вопросы
                </h1>
                <template>
                    <Accordion
                        :accordions="questions"
                        class="mb-6"
                        icon="mdi-chevron-down"
                        color="red"
                        dark
                    />
                </template>
                    <FooterHTML/>
            </div>
        </div>
    </div>
</template>

<script>
import FooterHTML from "@/components/html/FooterHTML.vue";
import Accordion from "@/components/global/Accordion.vue";

export default {
    components: {Accordion, FooterHTML},
    data: () => ({
        questions: [
            {
                id: 1,
                action: true,
                itemPanel: 'Что нужно, чтобы получить заём?',
                bodyPanel: '- Российское гражданство и паспорт\n' +
                    '- СНИЛС или водительское удостоверение\n' +
                    '- Мобильный телефон и электронная почта\n' +
                    '- ПТС, СТС и автомобиль'
            },
            {
                id: 2,
                action: true,
                itemPanel: 'Как проходит выдача займа?\n',
                bodyPanel: '1. Оформите заявку на сайте.\n' +
                    '2. Получите решение за несколько минут и подтвердите его. \n' +
                    '3. Загрузите фото автомобиля / СТС / ПТС и выберите способ получения денег. \n' +
                    '4. После оценки автомобиля получите свои деньги.'
            },
            {
                id: 3,
                action: true,
                itemPanel: 'Как быстро я получу деньги?\n',
                bodyPanel: 'Выбирайте банковскую карту для моментального зачисления после проверки автомобиля.'
            },
            {
                id: 4,
                action: true,
                itemPanel: 'Моя машина подойдёт?\n',
                bodyPanel: 'Оставьте заявку, чтобы узнать оценку вашего автомобиля всего через несколько минут.'
            },
            {
                id: 5,
                action: true,
                itemPanel: 'Что делать, если моё авто не одобрили?',
                bodyPanel: `Отправьте повторную заявку и получите денежные средства без залога.`
            },
            {
                id: 6,
                action: true,
                itemPanel: 'Как много времени это займёт?\n',
                bodyPanel: 'Всего несколько часов от подачи заявки до получения денег.'
            },
            {
                id: 7,
                action: true,
                itemPanel: 'Какая максимальная сумма займа?\n',
                bodyPanel: 'Сумма зависит от стоимости вашего автомобиля, узнаете её после оценки.'
            },
        ]
    })

}
</script>

<style lang="scss" scoped>
.footer {
     padding: 0 25px;
    margin-top: 64px;
    text-align: center;
}

</style>
