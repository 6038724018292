<template>
  <div>
    <h1>Вы изменили сумму кредита, желаете оформить новую заявку?</h1>

    <div class="buttons">
      <v-btn
        @click="$emit('changeTemplate', {success: true, prev: 'account', next: 'account'})"
        class="rectangle-button"
      >Да</v-btn>
      <v-btn
        @click="$emit('changeTemplate', {success: true, prev: 'back', next: prevRoute})"
        class="rectangle-button"
      >Нет</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    prevRoute: String
  },
  computed: {
    isNordwind(){
      return this.$root.state.isNordwind()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>