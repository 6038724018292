<template>
    <div class="block-4-body" id="cashback">
        <div class="southwind_block4">
            <div class="southwind-discount">
                <h1 class="southwind-discount_title">Летайте с выгодой</h1>
                <div class="discount-info">

                    <div v-if="urlParams('ac')" class="southwind-discount_info_item discount-item-1">
                        <div class="discount-info-block">
                            <img
                                class="discount-image"
                                src="@/assets/img/nordwind/nordwind_icon1.svg"
                            >
                        </div>
                        <p class="discount-info-text">Перечислим деньги на вашу карту за 2 минуты**
                        </p>
                    </div>

                    <div v-if="urlParams('acnew')" class="southwind-discount_info_item discount-item-1">
                        <div class="discount-info-block">
                            <img
                                class="discount-image"
                                src="@/assets/img/nordwind/nordwind_icon1.svg"
                                alt=""
                            >
                        </div>
                        <p class="discount-info-text">Перечислим деньги на вашу карту за 2 минуты**
                        </p>
                    </div>

                    <div class="southwind-discount_info_item discount-item-3">
                        <div class="discount-info-block">
                            <img
                                class="discount-image"
                                src="@/assets/img/nordwind/nordwind_icon3.svg"
                            >
                        </div>
                        <p
                            v-if="urlParams('ac')"
                            class="discount-info-text">Ежемесячно погашайте не позднее, чем за 10 дней до даты платежа
                        </p>
                        <p
                            v-if="urlParams('acnew')"
                            class="discount-info-text">Ежемесячно погашайте не позднее, чем за 10 дней до даты платежа
                        </p>
                    </div>
                    <div class="southwind-discount_info_item discount-item-2">
                        <div class="discount-info-block">
                            <img
                                class="discount-image"
                                src="@/assets/img/nordwind/nordwind_icon2.svg"
                                alt=""
                            >
                        </div>
                        <p class="discount-info-text">Вернём все уплаченные % кэшбеком
                        </p>
                    </div>

                </div>
            </div>
            <!--        <v-btn-->
            <!--            class=" modal-open discount-button button_center rectangle-button"-->
            <!--            data-modal="form-modal"-->
            <!--            @click="$vuetify.goTo('#widget', 500)"-->
            <!--            >Получить деньги-->
            <!--        </v-btn>-->
            <MainButton
                class=" modal-open discount-button button_center"
                data-modal="form-modal"
                @click="$vuetify.goTo('#widget', 500)"
                :altColor="'#D6181E'"
                >Получить деньги
            </MainButton>
            <a
                v-if="urlParams('ac')"
                class="discount-terms"
                href="./docs/index.pdf"
                target="_blank"
                >Полные условия акции
            </a>
            <a
                v-if="urlParams('acnew')"
                class="discount-terms"
                href="./docs/loan0.pdf"
                target="_blank"
                >Полные условия акции
            </a>
        </div>
    </div>
</template>

<script>
import MainButton from "@/components/global/MainButton.vue";

export default {
    name: "BlockHTML4Southwind.vue",
    components: {
        MainButton
    },
    methods: {
        urlParams(param){
            const urlLocation = location.search.split('&')
            return urlLocation.includes(param)
        },
    }
}
</script>

<style scoped lang="scss">
.southwind-discount {
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0px 3px 5px rgba(95, 99, 104, 0.05),
    0px 10px 10px rgba(95, 99, 104, 0.05),
    0px 1px 18px rgba(95, 99, 104, 0.05);
    margin-bottom: 50px;

    &_title {
        padding: 0 20px;
        @media (max-width: 500px) {
            font-size: 30px;
            padding: 20px 20px 0;
        }
    }
}

.southwind-discount_info_item {
    max-width: 336px;
    width: 100%;
    padding: 32px;
    min-height: 272px;
    margin: 0 16px 0 16px;
    border-radius: 16px;
    position: relative;

    .discount-info-block {
        height: 100px;

        img {
            max-height: 100%;
        }
    }

    .container-info-limit {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: baseline;

        .container-info-limit_description {
            width: 50%;
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 1.3em;
            display: flex;
            align-items: center;
            color: #333333;

            margin-bottom: 18px;
        }

        .container-info-limit_price {
            border-radius: 8px;
            height: 40px;
            font-family: RobotoBold;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 50%;
            margin: 0;

            p {
                font-family: NerisBlack;
                font-style: normal;
                font-weight: 900;
                font-size: 32px;
                line-height: 1.4em;
                display: flex;
                align-items: center;
                text-align: right;
                color: #0a0a0d;
                margin-bottom: 0 !important;
            }

            span {
                font-family: NerisBlack;
                font-style: normal;
                font-weight: 900;
                font-size: 32px;
                line-height: 1.4em;
                display: flex;
                align-items: center;
                text-align: right;
                color: #0a0a0d;
                padding: 0 0 2px 5px;
            }
        }
    }
}

.southwind-discount {
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0px 3px 5px rgba(95, 99, 104, 0.05),
    0px 10px 10px rgba(95, 99, 104, 0.05),
    0px 1px 18px rgba(95, 99, 104, 0.05);
    margin-bottom: 50px;

    &_title {
        padding: 24px 20px;
        @media (max-width: 500px) {
            font-size: 30px;
            padding: 20px 20px 0;
        }
    }
}
.southwind_block4 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
