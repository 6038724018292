export function moneyFormat(n) {
    return parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ").replace('.', ',');
}

export function moneyFormatWithSpace(n) {
    return moneyRounding(parseFloat(n)).toLocaleString().replace(/,/, ' ')
}

export function moneyFormatWithRegExp(n, searchValue, replaceValue) {
    return parseFloat(n).toLocaleString().replace(searchValue, replaceValue)
}

export function moneyRounding(n) {
    return Math.round(parseFloat(n));
}
