<template>
    <div>
        <p
            v-if="!$root.appType('travelata')"
            :class="$root.appType('vtb') ? 'vtb-error-text' : 'error-text'"
            class="text-left mb-sm-3 mb-5">
            {{ additionalText }}
        </p>
        <div v-if="$root.appType('travelata')" class="travelata_additional-info">
            <img src="~@/assets/img/travelata/travelata_attencion.svg" />
            <p
                class="travelata_error-text text-left mb-sm-3 mb-5">
                {{ additionalText }}
            </p>
        </div>
        <v-form ref="form">
            <div v-for="field in fields" :key="field.title">
                <AdditionalForms
                    :fields="field.items"
                    :conditions="field.conditions"
                    :title="field.title"
                    class="mb-6"
                />
            </div>

            <DocsInfo v-if="needPhoto" />

            <p
                v-if="errorText"
                :class="$root.appType('vtb') ? 'vtb-error-text' : 'error-text'"
                >{{ errorText }}
            </p>

            <v-btn
                :disabled="loading"
                :loading="loading"
                @click="sendDocs"
                :class="$root.appType('vtb') ? 'vtb-button' : 'rectangle-button'"
                class="mt-6"
                >
                Далее
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import DocsInfo from "@/components/DocsInfo";
import AdditionalForms from "@/components/modal/registration/AdditionalForms";

export default {
    components: { AdditionalForms, DocsInfo },

    computed: {
        additionalData() {
            return this.$root.anketa.additionalData;
        },

        additionalText() {
            let text = "Нам нужно еще немного информации. ";

            if (
                this.additionalData.employmentRequired ||
                this.additionalData.additionalPhoneNumberRequired
            ) {
                if (this.needPhoto) {
                    text += "Укажите дополнительные данные, прикрепите необходимые документы.";
                } else {
                    text += "Укажите дополнительные данные.";
                }
            } else if (this.needPhoto) {
                text += "Прикрепите необходимые документы.";
            }

            return text;
        },

        connector() {
            return this.$root.connector;
        },

        anketa() {
            return this.$root.anketa;
        },

        needPhoto() {
            if (
                this.additionalData.filesRequired ||
                this.additionalData.passportRequired ||
                this.additionalData.photoWithPassportRequired
            ) {
                return true;
            }
            return false;
        },

        isNordwind(){
            return this.$root.state.isNordwind()
        }
    },

    methods: {
        sendDocs() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                const data = {
                    Employment: {
                        employmentType: this.anketa.employmentType.value.value,
                        employer: this.anketa.organizationName.value,
                        position: this.anketa.position.value,
                        employmentPeriod:
                            this.anketa.employmentPeriod.value.value,
                        additionalPhoneNumber:
                            this.anketa.workPhone.cleanValue(),
                        phoneType: "work",
                    },

                    additionalPhoneNumber:
                        this.anketa.additionalPhone.cleanValue(),

                    passportPhoto1: this.anketa.photoPassport.file?.img,
                    passportPhoto2:
                        this.anketa.photoPassportRegistration.file.img,
                    photoWithPassportRequired:
                        this.anketa.photoSPassportWithUser.file.img,
                    filesRequired1:
                        this.anketa.photoSecondDocumentFront.file.img,
                    filesRequired2:
                        this.anketa.photoSecondDocumentBack.file.img,
                };

                // if(this.isNordwind){
                //     this.$root.metrika96799237.reachGoal("nmd_send");
                // }
                // else if(this.$root.appType('cash')) {
                //     this.$root.metrika88149184.reachGoal("nmd_send");
                // }
                // else if(this.$root.appType('vtb')) {
                //     this.$root.metrika97329470.reachGoal("nmd_send");
                // }

                this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("nmd_send");
                this.$root.mindbox("Отправил доп. документы");

                this.$emit("nextStep", "WaitingStatus", data);

                this.loading = false;
            }
        },

    },

    data: () => ({
        errorText: "",
        loading: false,
        fields: [
            {
                title: "Сведения о работе",
                items: [
                    "employmentType",
                    "organizationName",
                    "position",
                    "employmentPeriod",
                    "workPhone",
                ],
                conditions: ["employmentRequired"],
            },
            {
                title: "Дополнительный контакт",
                items: ["additionalPhone"],
                conditions: ["additionalPhoneNumberRequired"],
                grid: {}
            },
            {
                title: "Паспорт",
                items: [
                    "photoPassport",
                    "photoPassportRegistration",
                    "photoSPassportWithUser",
                ],
                conditions: ["passportRequired", "photoWithPassportRequired"],
            },
            {
                title: "Водительские права или СНИЛС",
                items: ["photoSecondDocumentFront", "photoSecondDocumentBack"],
                conditions: ["filesRequired"],
            },
        ],
    }),

    mounted() {
        // if(this.isNordwind){
        //     this.$root.metrika96799237.reachGoal("nmd");
        // }
        // else if(this.$root.appType('cash')){
        //     this.$root.metrika88149184.reachGoal("nmd");
        // }
        // else if(this.$root.appType('vtb')){
        //     this.$root.metrika97329470.reachGoal("nmd");
        // }
        this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("nmd");
        this.$root.mindbox("Запросили доп. документы");
    },
};
</script>


<style lang="scss" scoped>
@import '@/assets/scss/_travelata-variables.scss';

.additional-info_text {
    font-family: Neris, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.3em !important;
    letter-spacing: 0.3px;
    margin: 0 !important;

    color: #000000 !important;
}

.travelata {
    &_additional-info {
        display: flex;
        gap: 10px;
        align-items: flex-start;
    }

    &_error-text {


        @include tr-text();
        color: #333;
        font-family: 'OpenSansR';
    }
}


</style>
