<template>
    <div class="requirements">
        <div class="container-width">
            <h2 class="text-center">Требования к автомобилю</h2>

            <ul class="mx-auto mt-6 mt-sm-16">
                <li v-for="item in items" :key="item">
                    {{item}}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
data: () => ({
    items: [
        'Легковые',
        'Иномарки - не старше 25 лет',
        'Российские - не старше 10 лет',
        'Регистрация - на учете в Российской Федерации',
        'Состояние автомобиля - полная исправность, отсутствие серьезных повреждений',
        'Требования - авто не в залоге',
    ]
})
}
</script>

<style lang="scss" scoped>
.requirements {
    padding: 128px 24px 128px 24px;
    background-color: #F5F5F5;
    margin-top: 120px;

    ul {
        max-width: 750px;

        li {
            color: #0A0A0D;

            font-family: Neris;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            &::marker {
                font-size: 14px;
            }
        }
    }

}
</style>
