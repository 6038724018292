<template>
  <div class="block-2">
    <div class="container-width">
      <div class="block-2-body">
        <div class="back-logo">
          <img class="back-logo-image" src="../assets/img/pllogo48.svg" alt="logotype" />
        </div>
        <div class="block-2-announce">
          <div class="announce-description">
            <img class="announce-image" src="../assets/img/icon5.png" alt />
            <h1>
              Кредит наличными
              <br /> на любые траты
            </h1>
            <p
              class="h1-description color-red"
              id="credit-no-discount"
            >Для подачи заявки перейдите по ссылке из смс</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SMSInfo"
}
</script>

<style scoped>
</style>