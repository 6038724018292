<template>
    <div class="info-tooltip">
        <div v-if="$root.appType('cash') || $root.appType('auto') || $root.appType('nordwind')">
            <div class="info-tooltip_content">
                <p class="info-tooltip_content_text">
                    Указанный номер телефона уже зарегистрирован в сервисе под
                    другим аккаунтом. Укажите другой номер телефона.
                </p>
                <p class="info-tooltip_content-text">Нужна помощь?</p>
                <p class="info-tooltip_content-text">
                    Обратитесь по номеру
                    <a
                        href="tel:+78007700300"
                        class="info-tooltip_content_text_number"
                    >8 800 770 03 00
                    </a>
                </p>
            </div>
            <!-- <button @click="$emit('close-tooltip')" class="info-tooltip_close-btn">
                &#x2716;
            </button> -->

            <v-btn
                fab
                elevation="1"
                class="modal-icon modal-close info-tooltip_close-btn"
                @click="$emit('close-tooltip')"
            >
                <img src="../../assets/img/close.svg" alt="logotype" />
            </v-btn>
        </div>

        <div v-if="$root.appType('travelata')" class="travelata_info-tooltip_container">
            <div class="travelata_info-tooltip_image">
                <img src="../../assets/img/travelata/travelata_attencion.svg">
            </div>
            <div class="travelata_info-tooltip_content">
                <p class="travelata_info-tooltip_content_text">
                    Указанный номер телефона уже зарегистрирован в сервисе под
                    другим аккаунтом. Укажите другой номер телефона.
                </p>
                <p class="travelata_info-tooltip_content_text">Нужна помощь? Обратитесь по номеру
                    <a
                        href="tel:+78007700300"
                        class="travelata_info-tooltip_content_text_number"
                        >8 800 770 03 00
                    </a>
                </p>
            </div>
            <!-- <button @click="$emit('close-tooltip')" class="info-tooltip_close-btn">
                &#x2716;
            </button> -->

<!--            <v-btn-->
<!--                fab-->
<!--                elevation="1"-->
<!--                class="modal-icon modal-close info-tooltip_close-btn"-->
<!--                @click="$emit('close-tooltip')"-->
<!--            >-->
<!--                <img src="../../assets/img/close.svg" alt="logotype" />-->
<!--            </v-btn>-->
        </div>

    </div>
</template>

<script>
export default {
    name: "InfoTooltip",
    methods: {

    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_travelata-variables.scss";

.info-tooltip {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    justify-content: space-between;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    position: relative;
    margin-top: 20px;

    &_content {
        &_text {
            margin: 0 !important;

            &_number {
                color: #ff5252;
                text-decoration: none;
            }
        }
    }

    &_close-btn {
        // cursor: pointer;
        // padding: 2px 9px;
        // font-size: 20px;
        // color: #000;
        // background-color: lightgray;
        // font-family: sans-serif;
        // border-radius: 50%;

        position: relative !important;
        right: -6px !important;
    }
}

.travelata {
    &_info-tooltip {
        &_container {
            display: flex;
            gap: 20px;
        }
        &_content {
            @include tr-text();
            font-family: 'OpenSansR';
            color: #333;
            &_text {
                text-align: left;

                &_number {
                    color: #059BCE;
                    text-decoration: none;
                }
            }
        }
    }
}
</style>
