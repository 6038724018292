<template>
    <div class="main_button">
        <v-btn
            @click="$emit('click')"
            :class="[addButtonStyles, addAltColor]"
            :disabled="disabled"
            :loading="loading"
            :style="{borderRadius: addRadius}"
            >
            <slot></slot>
        </v-btn>
    </div>
</template>
<script>
export default {
    name: "MainButton",
    props: {
        disabled: Boolean,
        loading: Boolean,
        altColor: String,
        radius: Number
    },
    data(){
        return {

        }
    },
    computed: {
        addButtonStyles(){
            return this.$root.appType('cash') || this.$root.appType('auto') ||
            this.$root.appType('nordwind') ||
            this.$root.appType('bankiros') ? 'rectangle-button' : `${this.$root.state.type()}-button`
        },
        addAltColor(){
            return this.altColor != undefined ? 'alt_color' : ''
        },
        addRadius(){
            return this.radius != undefined ? `${this.radius}px !important`: ''
        }
    },
    // mounted(){
    //     console.log('main button', this.altColor)
    // }

}
</script>

<style scoped lang="scss">
.main_button {
    margin-top: 32px;
}

.southwind{

    //&_form_title {
    //    @include vtb-form-title;
    //}

    &-button {
        background: #AB292D !important;
        //background: v-bind(addAltColor) !important;
        text-transform: initial !important;
        border-radius: 12px !important;
        line-height: 59px !important;
        height: 64px !important;
        font-style: normal;
        font-family: NerisBold !important;
        font-weight: 600;
        font-size: 20px !important;
        color: #ffffff !important;
        text-align: center;
        text-decoration: none;
        text-decoration-line: none;
        text-decoration-thickness: initial;
        text-decoration-style: initial;
        text-decoration-color: initial;
        display: block;
        width: 100% !important;
        margin: 0 auto;

        &_light {
            background: #D6181E !important;
        }
        &-prime {
            font-size: 24px;
        }
        cursor: pointer;

        //&:hover {
        //    background: $color-8;
        //}
        //
        //&:focus {
        //    background: $color-8;
        //}

        &.active {
            background-color: #26a212 !important;
        }

        //& .v-btn__content {
        //    @include vtb-button;
        //}
    }

    &-error-text {
        color: var(--v-primary-base) !important;
    }
}
.alt_color {
    background: v-bind(altColor) !important;
}
</style>
