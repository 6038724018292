<template>
    <div
        id="cashback"
        class="travelata_block4"
        >
        <div class="travelata_block4_container">
            <h2 class="travelata_block4_title">Путешествуйте с выгодой</h2>
            <ul class="travelata_block4_list">
                <li class="travelata_block4_list_item">
                    <img class="travelata_block4_list_item_img" src="~@/assets/img/travelata/travelata_wallet.png"/>
                    <p class="travelata_block4_list_item_text">Не нужно оформлять новый финансовый продукт. Перечислим деньги на вашу карту за 2 минуты <sup>**</sup></p>
                </li>
                <li class="travelata_block4_list_item">
                    <img class="travelata_block4_list_item_img" src="~@/assets/img/travelata/travelata_percent.png">
                    <p class="travelata_block4_list_item_text">Вернём оплаченную вами стоимость тура частично или полностью</p>
                </li>
                <li class="travelata_block4_list_item">
                    <img class="travelata_block4_list_item_img" src="~@/assets/img/travelata/travelata_calendar.png">
                    <p class="travelata_block4_list_item_text">Вносите ежемесячные платежи согласно графику или оплачивайте всю сумму в удобное для вас время</p>
                </li>
            </ul>
        </div>
        <div class="travelata_block4_buttons">
            <v-btn
                class=" modal-open discount-button travelata_block4_button"
                data-modal="form-modal"
                @click="$vuetify.goTo('#widget', 500)"
                >Получить деньги
            </v-btn>
            <a
                v-if="urlParams('ac')"
                class="travelata_block4_link"
                href="./docs/index.pdf"
                target="_blank"
                >Полные условия акции
            </a>
            <a
                v-if="urlParams('acnew')"
                class="travelata_block4_link"
                href="./docs/loan0.pdf"
                target="_blank"
                >Полные условия акции
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "BlockHTML4Travelata.vue",
    methods: {
        urlParams(param){
            const urlLocation = location.search.split('&')
            return urlLocation.includes(param)
        },
    }
}

</script>
<style scoped lang="scss">
@import "@/assets/scss/_travelata-variables.scss";

.travelata_block4 {
    margin-bottom: 131px;
    @media (max-width: 1000px) {
        margin-top: 30px;
    }

    &_container {
        background-color: #fff;
        border-radius: 4px;
        padding: 60px 20px;
        margin-bottom: 60px;

        @media (max-width: 350px) {
            padding: 30px 20px;
        }
    }
    &_title {
        @include tr-text(44px, 800, 48px);
        font-family: 'OpenSansEB';
        color: #333;
        text-align: center;
        margin-bottom: 46px;

        @media (max-width: 700px) {
            font-size: 30px !important;
        }
        @media (max-width: 450px) {
            font-size: 24px !important;
        }
    }
    &_list {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
        //@media (max-width: 500px) {
        //
        //}

        &_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 320px;
            width: 100%;

            &_img {
                margin-bottom: 18px;
            }

            &_text {
                @include tr-text(18px, 400, 28px);
                font-family: 'OpenSansR';
                color: #333;

                @media (max-width: 500px) {
                    font-size: 18px !important;
                }
            }
        }
    }

    &_buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &_button {
        max-width: 352px;
        width: 100%;
        height: 64px !important;
        background-color: #059BCE !important;
        border-radius: 4px;
        color: #fff;
        @include tr-text(20px, 600, 30px);
        font-family: 'OpenSansSB';
        text-transform: unset;
        margin-bottom: 24px;

    }

    &_link {
        @include tr-text(18px);
        color: #A7A7A7;
        text-decoration: underline;
        font-family: 'OpenSansR';
    }
}
</style>
