<template>
    <div class="error-info">
        <div class="error-info_container">
            <div class="error-info_attention">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20C4.477 20 0 15.523 0 10C0 4.478 4.477 0 10 0C15.523 0 20 4.478 20 10C20 15.523 15.523 20 10 20ZM10 5C9.73478 5 9.48043 5.10536 9.29289 5.29289C9.10536 5.48043 9 5.73478 9 6V11C9 11.2652 9.10536 11.5196 9.29289 11.7071C9.48043 11.8946 9.73478 12 10 12C10.2652 12 10.5196 11.8946 10.7071 11.7071C10.8946 11.5196 11 11.2652 11 11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" :fill="getMainColor"/>
                </svg>
            </div>
            <div class="error-info_container_info">
                <div class="error-info_container_text">
                    Указанный номер телефона уже зарегистрирован под другим аккаунтом.
                    Проверьте правильность введённых данных.
                    Если данные верные, то просьба направить скан подписанного заявления,
                    а также фото лицевой стороны паспорта (стр. 2-3) на электронную почту <a href="mailto:info@paylate.info">info@paylate.info.</a>
                </div>
                <div class="error-info_container_text_link">
                    <svg class="error-info_statement" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.0833 8.49169H14.6749C12.6999 8.49169 11.0916 6.88335 11.0916 4.90835V2.50002C11.0916 2.04169 10.7166 1.66669 10.2583 1.66669H6.72492C4.15825 1.66669 2.08325 3.33335 2.08325 6.30835V13.6917C2.08325 16.6667 4.15825 18.3334 6.72492 18.3334H13.2749C15.8416 18.3334 17.9166 16.6667 17.9166 13.6917V9.32502C17.9166 8.86669 17.5416 8.49169 17.0833 8.49169ZM10.2333 13.15L8.56659 14.8167C8.50825 14.875 8.43325 14.925 8.35825 14.95C8.28325 14.9834 8.20825 15 8.12492 15C8.04158 15 7.96659 14.9834 7.89159 14.95C7.82492 14.925 7.75825 14.875 7.70825 14.825C7.69992 14.8167 7.69158 14.8167 7.69158 14.8084L6.02492 13.1417C5.78325 12.9 5.78325 12.5 6.02492 12.2584C6.26659 12.0167 6.66658 12.0167 6.90825 12.2584L7.49992 12.8667V9.37502C7.49992 9.03335 7.78325 8.75002 8.12492 8.75002C8.46659 8.75002 8.74992 9.03335 8.74992 9.37502V12.8667L9.34992 12.2667C9.59159 12.025 9.99158 12.025 10.2333 12.2667C10.4749 12.5084 10.4749 12.9084 10.2333 13.15Z" :fill="getMainColor"/>
                        <path d="M14.5251 7.34207C15.3167 7.3504 16.4167 7.3504 17.3584 7.3504C17.8334 7.3504 18.0834 6.79207 17.7501 6.45873C16.5501 5.2504 14.4001 3.0754 13.1667 1.84207C12.8251 1.5004 12.2334 1.73373 12.2334 2.20873V5.11707C12.2334 6.33373 13.2667 7.34207 14.5251 7.34207Z" :fill="getMainColor"/>
                    </svg>
                    <a href="https://paylate.ru/documents/platform/izmenenie-pd.docx">заявление</a>
                </div>
                <p class="error-info_container_text">
                    Нужна помощь? Обратитесь по номеру <a class="underline-none" href="tel:+78007700300">8 (800) 770-0300</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ErrorInfo",
    data(){
        return {
            colorsByType: {
                cash: '#EB3B66',
                nordwind: '#AB292D',
                vtb: '#1976D2',
                travelata: '#EC933D',
                bankiros: '#95CE5A',
                southwind: '#AB292D',
                auto: '#EB3B66'
            }
        }
    },
    computed: {
        getMainColor(){
            return this.colorsByType[`${this.$root.state.type()}`]
        }
    }
}
</script>

<style scoped lang="scss">
.error-info {
    &_container {
        border: 1px solid #EFEFEF;
        border-radius: 12px;
        background-color: #FCFCFC;
        padding: 20px;
        display: flex;
        gap: 10px;
        text-align: left;

        &_text {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.4px;
            color: #0A0A0D;

            &_link {
                position: relative;
                margin: 5px 0;
                & > a {
                    //position: absolute;
                    //top: 0;
                    //left: 30px;
                    text-transform: uppercase;
                }
            }
        }

        a {
            color: v-bind(getMainColor);
        }
    }
    &_statement {
        position: relative;
        top: 4px;
        left: 0;
        margin-right: 10px;
    }
}
.underline-none {
    text-decoration: none;
}
</style>
