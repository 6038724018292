<template>
    <div
        v-if='value'
        :class="addClass('tooltip')"
        >
        <div class='d-flex align-center justify-space-between'>
            <!-- <h4>{{ packet.name === 'Премиум' ? 'Оптимальный' : packet?.name }}</h4> -->
            <h4
                :class="addClass('tooltip_title')"
                >{{ anketa.creditInformation.insuranceData[2].name === 'Премиум' ? 'Оптимальный' : anketa.creditInformation.insuranceData[2]?.name }}
            </h4>
            <v-btn
                fab
                elevation='1'
                class='modal-icon'
                @click='close'
                >
                <img src='../../assets/img/close.svg' alt='logotype' />
            </v-btn>
        </div>

        <!-- расскоментить задача pl-198 -->
        <ul
            :class="addClass('tooltip_list')"
            class='mt-3 list '
            >
            <li
                v-for='doc in listToshow'
                :key='doc.documentName'
                :class="addClass('tooltip_list_item')"
                >
                <a
                    :class="[doc.classToAdd !== undefined ? doc.classToAdd : '', doc.hasLink ? 'add-cursor' : '']"
                    :href="doc.url"
                    target="_blank"
                    >{{ doc.text}}
                </a>
            </li>
        </ul>
        <!-- <ul
            v-for='insurancelist in packet.listDescription'
            :key='insurancelist.name'
            :class="$root.appType('vtb') ? 'vtb_second-list' : 'second-list'"
            class='mt-3 list '
            >
            <li class='text-left'>{{ insurancelist.name }}</li>
        </ul> -->

        <div class='tooltip__bottom mt-12 '>
            <v-btn
                @click='selectPacket'
                :disabled='selected && !chosen'
                :class="[chooseBorder, {active: selected && chosen}, $root.appType('travelata') || $root.appType('southwind') ? 'travelata_packet-tooltips_button' : '']"
                class="tooltip__bottom_button "
                >{{ chosen ? "Выбран" : "Отключён" }}
            </v-btn>

            <div class="tooltip__bottom_sum">
                <!-- <p v-if="$root.appType('vtb')" class="tooltip__bottom_sum_text">Итого:</p> -->
                <p
                    :class="$root.appType('travelata') ? 'travelata_tooltip__bottom_sum_text' : ''"
                    class="tooltip__bottom_sum_text">Итого:</p>
                <p
                    :class="$root.appType('travelata') ? 'travelata_tooltip__bottom_sum_sum' : ''"
                    class='tooltip__bottom_sum_sum'>
                    <!-- {{ packet.insuranceData[2].cost }} -->
                      {{ anketa.creditInformation.insuranceData[2].cost }}
                    <span> &#8381;</span>
                    <!-- <span class='light-grey--text font-weight-light'>/ в день</span> -->
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default  {
    data: () => ({
        selected: false,
        chosen: true,
        documentsTextList:[
            {
                text: 'Страхование от несчастных случаев',
                documentName: 'statement_life',
                id: 1,
                classToAdd: 'tooltip_list_item_link',
                hasLink: false
            },
            {
                text: 'КИД НС',
                documentName: 'info_life',
                id: 2,
                classToAdd: 'tooltip_list_item_link',
                hasLink: false
            },
            {
                text: 'Страхование от потери дохода',
                documentName: 'statement_work',
                id: 3,
                classToAdd: 'tooltip_list_item_link',
                hasLink: false
            },
            {
                text: 'КИД ПД',
                documentName: 'info_work',
                id: 4,
                classToAdd: 'tooltip_list_item_link',
                hasLink: false
            },
            {
                text: 'Расширенное смс-информирование',
                documentName: 'statement_sms',
                id: 5,
                classToAdd: 'tooltip_list_item_link',
                hasLink: false
            },
            {
                text: 'Заявление на предоставление услуг (работ/товаров)',
                documentName: 'statement_services',
                id: 6,
                classToAdd: 'toolTip_list_application',
                hasLink: false
            },
            {
                text: 'Заявление страхование от несчастных случаев и болезней',
                documentName: 'insur_life',
                id: 7,
                classToAdd: 'tooltip_list_item_link',
                hasLink: false
            },
            {
                text: 'Заявление страхование от потери дохода',
                documentName: 'insur_work',
                id: 8,
                classToAdd: 'tooltip_list_item_link',
                hasLink: false
            },
            {
                text: 'Кредитный договор',
                documentName: 'consolidated',
                id: 9,
                classToAdd: 'tooltip_list_item_link',
                hasLink: false
            }
        ],
        listItemsToHide: ['insur_life', 'insur_work', 'consolidated'],
        exceptionsTypes: ['cash','auto', 'nordwind', 'vtb']
    }),
    props: {
        value: Boolean,
        packet: Object,
    },
    mounted() {
        this.$emit("unlockingTarif");
        this.$emit('choiseTarif', 3);
        console.log('PacketTooltip packet', this.packet)
        console.log('packet tooltip anketa.creditInformation', this.anketa.creditInformation)

    },
    methods: {
        selectPacket() {
            if (this.selected) {
                this.chosen = false;
            }

            this.selected = true;
        },

        close() {
            if (!this.chosen) {
                this.$emit("disableTooltip");
            }

            this.$emit("input");
        },

        addClass(cssClass){
            let searchingClass = null
            // console.log('addClass', this.exceptionsTypes.find(this.$root.state.type()))
            if(this.exceptionsTypes.find(el => el === this.$root.state.type())) {
                searchingClass = `${cssClass}`
            }else {
                searchingClass = `${this.$root.state.type()}_${cssClass}`
            }
            return searchingClass
        },

    },
    computed: {
        isNordwind(){
            return this.$root.state.isNordwind()
        },
        chooseBorder(){
            let cssClass = null
            if(this.$root.appType('vtb')){
                cssClass = 'vtb-border-button'
            }
            // else if(this.$root.appType('nordwind')) {
            //     cssClass = 'nordwind-border-button'
            // }
            else if(this.$root.appType('travelata')) {
                cssClass = 'travelata-border-button'
            }
            else if(!this.$root.appType('vtb')) {
                cssClass = 'cash-border-button'
            }

            return cssClass
        },

        comparableText(){
            let newList = [];
            // let otherList = []
            //     this.packet.creditDocument.forEach(doc => {
            //         this.documentsTextList.forEach(obj => {
            //             if(doc.documentName === obj.documentName) {
            //                 newList.push(Object.assign(doc, obj))
            //             }else if(doc.documentName !== obj.documentName && newList.includes(obj.documentName)){
            //                 newList.push(obj)
            //             }
            //
            //
            //         })
            //         // otherList = this.documentsTextList.filter(el => el.documentName !== doc.documentName)
            //     })
                this.documentsTextList.forEach(obj => {
                    this.packet.creditDocument.forEach(doc => {
                        if(doc.documentName === obj.documentName) {
                            Object.assign(obj, doc)
                        }




                    })
                    // if(newList.includes(obj.documentName)){
                    //     newList.push(obj)
                    // }
                    // otherList = this.documentsTextList.filter(el => el.documentName !== doc.documentName)
                })
                // newList = this.documentsTextList.concat(this.packet.creditDocument)
                // for(let i = 0; i < newList.length; i++){
                //
                // }
                // newList = newList.filter(item => !this.listItemsToHide.includes(item.documentName) ? item.documentName : '')
                // newList = newList.sort((a,b) => (a.id> b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
                // newList = newList.sort((a,b) => (a.id> b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
                newList = this.documentsTextList
                newList = newList.sort((a,b) => (a.id> b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
                console.log('packet-tooltip comparableText', this.documentsTextList)
            return newList
        },

        listToshow(){
            let list = []
            list = this.comparableText.filter(item => !this.listItemsToHide.includes(item.documentName) ? item.documentName : '')
            list.forEach(item => {
                if(item.url !== undefined && item.url !== "") {
                    item.hasLink = true;
                }
            })
            return list
        },
        anketa(){
            return this.$root.anketa
        },


    }
};
</script>

<style lang='scss' scoped>
@import "@/assets/scss/_vtb-variables.scss";
@import "@/assets/scss/_travelata-variables.scss";

.tooltip {
    position: absolute;
    padding: 20px;
    border: 1px solid #E9E9E9;
    border-radius: 12px;
    background-color: #fff;
    z-index: 100;
    bottom: 10px;
    left: 0;
    width: 343px;
    // @media (max-width: 720px) {
    //     margin-bottom: 50px;
    // }
    // @media (max-width: 380px) {
    //     margin-bottom: 70px;
    // }
    // @media (max-width: 375px) {
    //     margin-bottom: 90px;
    // }
    // @media (max-width: 371px) {
    //     margin-bottom: 110px;
    // }
    // // @media (max-width: 350px) {
    // //     margin-bottom: 110px;
    // // }
    // @media (max-width: 342px) {
    //     margin-bottom: 130px;
    // }
    @media (max-width: 342px) {
        width: 100%;
    }
    &_title {
        font-size: 18px;
        font-weight: 900;
        color: rgba(80, 80, 80, 1);
        line-height: 25.2px;
        letter-spacing: -0.4px;
    }

    &_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        padding: 0 !important;

        @media (max-width: 342px) {
            flex-wrap: nowrap;
            flex-direction: column;
        }


        &_item {
            padding: 0;
            &_link {
                font-family: Roboto !important;
                font-weight: 400 !important;
                font-size: 14px !important;
                line-height: 28px !important;
                letter-spacing: -0.3px !important;
                color: rgba(0, 0, 0, 0.4) !important;
                cursor: default !important;
                &.add-cursor {
                    cursor: pointer !important;
                }
            }
        }
    }

    .modal-icon {
        width: 24px !important;
        height: 24px !important;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        &_sum {
            max-width: 100px;
            display: flex;
            justify-content: space-between;
            gap: 10px;

            &_text {
                @include vtb-form-text;
                color: #656565;
            }
        }
    }

    &__bottom_sum{
        max-width: unset !important;
        &_text {
            font-family: Roboto;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.3px;
            color: rgba(101, 101, 101, 1);
        }

        &_sum {
            font-family: Roboto;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.3px;
            color: rgba(101, 101, 101, 1);
        }
    }
}

.toolTip_list_application {
    font-family: Roboto;
    font-size: 12px !important;
    color: rgba(80, 80, 80, 1) !important;
    line-height: 20px !important;
    letter-spacing: -0.3px !important;
    text-decoration: underline !important;
}
.travelata_tooltip {
    position: absolute;
    padding: 20px;
    border: 1px solid #E9E9E9;
    border-radius: 12px;
    background-color: #fff;
    z-index: 100;
    bottom: 60px;
    left: 0;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.10), 0px 8px 24px 0px rgba(17, 17, 26, 0.10), 0px 16px 56px 0px rgba(17, 17, 26, 0.10);
    // @media (max-width: 720px) {
    //     margin-bottom: 50px;
    // }
    // @media (max-width: 380px) {
    //     margin-bottom: 70px;
    // }
    // @media (max-width: 375px) {
    //     margin-bottom: 90px;
    // }
    // @media (max-width: 371px) {
    //     margin-bottom: 110px;
    // }
    // // @media (max-width: 350px) {
    // //     margin-bottom: 110px;
    // // }
    // @media (max-width: 342px) {
    //     margin-bottom: 130px;
    // }
    @media (max-width: 342px) {
        width: 100%;
    }
    &_title {
        @include tr-text(20, 600);
        color: #333;
        font-family: 'OpenSansSB';
    }

    &_list {
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;
        gap: 10px;
        padding: 0 !important;
        max-width: 300px;

        @media (max-width: 342px) {
            flex-wrap: nowrap;
            flex-direction: column;
        }


        &_item {
            padding: 0;
            &_link {
                @include tr-text(14, 400, 24px, -1px);
                color: #707070 !important;
                font-family: 'OpenSansR' !important;

            }
        }
    }

    .modal-icon {
        width: 24px !important;
        height: 24px !important;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        &_sum {
            max-width: 100px;
            display: flex;
            justify-content: space-between;
            gap: 10px;

            &_text {
                @include vtb-form-text;
                color: #656565;
            }
        }
    }

    &__bottom_sum{
        max-width: unset !important;
        &_text {
            font-family: Roboto;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.3px;
            color: rgba(101, 101, 101, 1);
        }

        &_sum {
            font-family: Roboto;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.3px;
            color: rgba(101, 101, 101, 1);
        }
    }
}


.southwind_tooltip {
    position: absolute;
    padding: 20px;
    border: 1px solid #E9E9E9;
    border-radius: 12px;
    background-color: #fff;
    z-index: 100;
    bottom: 60px;
    left: 0;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(17, 17, 26, 0.10), 0px 8px 24px 0px rgba(17, 17, 26, 0.10), 0px 16px 56px 0px rgba(17, 17, 26, 0.10);
    // @media (max-width: 720px) {
    //     margin-bottom: 50px;
    // }
    // @media (max-width: 380px) {
    //     margin-bottom: 70px;
    // }
    // @media (max-width: 375px) {
    //     margin-bottom: 90px;
    // }
    // @media (max-width: 371px) {
    //     margin-bottom: 110px;
    // }
    // // @media (max-width: 350px) {
    // //     margin-bottom: 110px;
    // // }
    // @media (max-width: 342px) {
    //     margin-bottom: 130px;
    // }
    @media (max-width: 342px) {
        width: 100%;
    }
    &_title {
        @include tr-text(20, 600);
        color: #333;
        font-family: 'OpenSansSB';
    }

    &_list {
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;
        gap: 10px;
        padding: 0 !important;
        max-width: 300px;

        @media (max-width: 342px) {
            flex-wrap: nowrap;
            flex-direction: column;
        }


        &_item {
            padding: 0;
            &_link {
                @include tr-text(14, 400, 24px, -1px);
                color: #707070 !important;
                font-family: 'OpenSansR' !important;

            }
        }
    }

    .modal-icon {
        width: 24px !important;
        height: 24px !important;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        &_sum {
            max-width: 100px;
            display: flex;
            justify-content: space-between;
            gap: 10px;

            &_text {
                @include vtb-form-text;
                color: #656565;
            }
        }
    }

    &__bottom_sum{
        max-width: unset !important;
        &_text {
            font-family: Roboto;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.3px;
            color: rgba(101, 101, 101, 1);
        }

        &_sum {
            font-family: Roboto;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.3px;
            color: rgba(101, 101, 101, 1);
        }
    }
}
// .second-list {
//     li {
//         font-size: 16px;
//         line-height: 1.4em;
//         color: #848485;
//         text-align: left;
//         position: relative;

//         padding-left: 15px;

//         &:before {
//             background: #848485;
//         }
//     }
// }
// .vtb_second-list {
//     li {
//         font-size: 16px;
//         line-height: 1.4em;
//         color: #848485;
//         text-align: left;
//         position: relative;

//         padding-left: 15px;

//         // &:before {
//         //     content: '';
//         //     // background: url('@/assets/img/vtb/vtb-modal_list-item.png');
//         //     background: url('../../assets/img/vtb/vtb-modal_list-item.png');
//         //     width: 13px;
//         //     height: 16px;
//         //     background-repeat: no-repeat;
//         //     border-radius: unset;
//         //     position: absolute;
//         //     left: -7px;
//         //     top: 2px;
//         // }
//     }
// }
.travelata-border-button {

}

.travelata_tooltip__bottom_sum_sum {
    @include tr-text(16px, 700);
    font-family: 'OpenSansB';
    color: #333;

    & > span {
        font-family: Roboto !important;


    }
}

.travelata_tooltip__bottom_sum_text {
    @include tr-text();
    font-family: 'OpenSansR';
    color: #707070;
}
//.theme--light.v-btn.v-btn--disabled.v-btn--has-bg.v-btn.travelata_packet-tooltips_button {
//    &:disabled {
//        background-color: #fff;
//    }
//}

</style>
