<template>
    <div :key="componentKey">
        <div v-if="!showOtherBanks">
            <div class="main-option-for-app">
                <h1
                    v-if="$root.appType('cash') || $root.appType('auto')"
                    >
                    <img src="../../assets/img/icon4.png" alt /> Заявка одобрена!
                </h1>
<!--                <h1-->
<!--                    v-if="$root.appType('vtb')"-->
<!--                    class="vtb_form_title"-->
<!--                    >Заполнение анкеты не требуется-->
<!--                </h1>-->
                <h1
                    v-if="!$root.appType('cash') && !$root.appType('auto')"
                    class="vtb_form_title"
                    >Подтверждение
                </h1>

                <!--        <p v-if="this.anketa.creditInformation.approvedAmountDifference">Сумма заявки была изменена</p>-->
                <p
                    :class="addClass('forms_subtitle')"
                    class="text-left mt-4 mb-4"
                    >{{subtitleText}}
                </p>
            </div>

            <v-snackbar
                :value="creditInfo.approvedAmountDifference"
                color="#26a212"
                timeout="6000"
                top
                >
                <p>
                    Сумма Вашей заявки была изменена. <br/>
                    Новая сумма {{ creditInfo.requestedSumm }} &#8381;
                </p>

                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="
                            creditInfo.approvedAmountDifference = false;
                            forceRerender();
                        "
                        >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>

            <div
                v-if="$root.appType('cash') || $root.appType('nordwind')"
                class="account-info-well mb-5">
                <table class="table-well">
                    <tr>
                        <td>Сумма заявки</td>
                        <td>
                            <b>{{ creditInfo.requestedSumm }} &#8381;</b>
                        </td>
                    </tr>
                </table>
            </div>

            <ToogleButton
                :options="filteredByAppTypePaymentMethod"
                :limit="40000"
                @toogle-payent="toogleHandler"
            />

            <!-- блок при выборе СБП -->
            <div
                v-if="templateChoose === 'sbp'"
                class="sbp"
                >
                <h2 :class="$root.state.type()"  class="account_form_title">Выберите банк:</h2>
                <div
                    :style="gridContainerStyles"
                    class="sbp_container"
                    >
                    <button
                        v-for="bank in banksToShow"
                        :key="bank.bank_name"
                        @click="selectBank"
                        :id="bank.member_name"
                        :data-bank-name="bank.bank_name"
                        :class="{'selected-bank': selectedBank !== null && bank.bank_name === selectedBank.bank_name}"
                        class="sbp_container_item"
                        >
                        <img
                            v-if="bank.bank_name !== 'Другой банк'"
                            class="sbp_container_item_image"
                            :src="!bank.showFakeLogo ? bank.logoURL : fakeLogo"
                            :alt="bank.bank_name"
                        />
<!--                        <img-->
<!--                            v-if="bank.bank_name !== 'Другой банк' && bank.showFakeLogo"-->
<!--                            class="sbp_container_item_image"-->
<!--                            :src="fakeLogo"-->
<!--                            :alt="bank.bank_name"-->
<!--                        />-->
                        <p >{{ bank.name }}</p>
                    </button>

                    <button
                        @click="selectBank"
                        :id="anotherBank.member_name"
                        :data-bank-name="anotherBank.bank_name"
                        :class="{'selected-bank': selectedBank !== null && anotherBank.selected}"
                        class="sbp_container_item"
                        >
                        <img
                            v-if="otherBanksShowLogo"
                            class="sbp_container_item_image"
                            :src="otherBanksShowLogo.logo"
                            :alt="anotherBank.bank_name"
                        />
<!--                        <img -->
<!--                            v-if="selectedBank !== null && testingToShowImage(selectedBank.logoURL)" -->
<!--                            class="sbp_container_item_image" -->
<!--                            :src="selectedBank.logoURL" -->
<!--                            :alt="selectedBank.bank_name"-->
<!--                        />-->
<!--                        <img -->
<!--                            v-if="selectedBank !== null && !testingToShowImage(selectedBank.logoURL)" -->
<!--                            class="sbp_container_item_image" -->
<!--                            :src="anotherBank.logo" -->
<!--                            :alt="selectedBank.bank_name"-->
<!--                        />-->
                        <p >{{ anotherBank.bank_name }}</p>
                    </button>
                </div>
<!--                <v-btn-->
<!--                    @click="confirmBank"-->
<!--                    :class="$root.appType('vtb') ? 'vtb-button' : 'rectangle-button'"-->
<!--                    class="mt-4"-->
<!--                    :style="reqestButtonStyles"-->
<!--                    >-->
<!--                    <span class="sbp_request-button">{{btnSelectedBankCondition }}</span>-->

<!--                </v-btn>-->
                <MainButton
                    @click="confirmBank"
                    class="mt-4"
                    :style="reqestButtonStyles"
                    >
<!--                    <span class="sbp_request-button">{{btnSelectedBankCondition }}</span>-->
                    <span class="sbp_request-button">Далее</span>
                </MainButton>
            </div>



            <div class="info-toggle-item info-toggle-item-1">
                <!-- блок счёт в банке -->
                <v-form
                    ref="bik"
                    v-show="templateChoose === 'accountInBank'"
                    >
                    <h2 :class="$root.state.type()" class="account_form_title">Введите реквизиты счета:</h2>
                    <FieldsCard :anketaNames="['accountNumberBank']" />
                    <FieldsCard :anketaNames="['accountBank']" />

                    <v-btn
                        @click="validate" id="accountInBankBtn"
                        :class="$root.appType('vtb') ? 'vtb-button' : 'rectangle-button'"
                        class="mt-4"
                        >Получить
                        <span class="ml-4">
                            <!-- {{ payment.toLocaleString().replace(/,/, " ") }} -->
                            {{ creditInfo.requestedSumm }}
                            <b class="ruble-icon">&#8381;</b>
                        </span>
                    </v-btn>
                </v-form>
                <!-- блок перевод на карту -->
                <v-form
                    @submit.prevent
                    ref="card"
                    v-if="creditInfoWithoutSpace <= 40000 && templateChoose === 'card' && !$root.appType('vtb')"
                    >
                    <h2 :class="$root.state.type()" class="account_form_title">Введите номер карты:</h2>
                    <FieldsCard :anketaNames="['accountCard']" />
                    <v-btn
                        @click="validate" id="cardBtn"
                        :class="$root.appType('vtb') ? 'vtb-button' : 'rectangle-button'"
                        class="mt-4"
                        >Получить
                        <span class="ml-4">
                            <!-- {{ payment.toLocaleString().replace(/,/, " ") }}-->
                            {{ creditInfo.requestedSumm }}
                            <b class='ruble-icon'>&#8381;</b>
                        </span>
                    </v-btn>
                </v-form>
            </div>

            <div class="hidden">
                <div class="modal-screen-desc">
                    <p class="desc-tooltip">Серия и номер паспорта</p>
                </div>
                <input
                    type="text"
                    autocomplete="off"
                    placeholder="Введите данные паспорта"
                />
            </div>
        </div>

        <!-- блок с другими банками -->
        <div
            v-else-if="showOtherBanks"
            class="other-banks"
            >
            <div
                @click="otherBanksBack"
                class="other-banks_back"
                >
                <img
                    src="../../assets/img/arrow.svg"
                    alt="back"
                />
            </div>
            <h2 class="other-banks_title">Другой банк</h2>
            <div class="other-banks_search-field_container">
                <input
                    type="text"
                    class="other-banks_search-field"
                    placeholder="Введите название"
                    v-model="sbpSearchField"
                />
                <img class="other-banks_search-field_img" src="@/assets/img/search.svg" alt="поиск">
            </div>
            <ul class="other-banks_list">
                <li
                    v-for="bank in otherBanks"
                    :key="bank.bank_name"
                    @click="chooseAnotherBank"
                    :data-bank-name="bank.bank_name"
                    class="other-banks_list_item">
                    <!-- <img
                        src=""
                        alt=""
                        class="other-banks_list_item_img"
                    /> -->
                    <p class="other-banks_list_item_text">{{ bank.member_name_rus }}</p>
                </li>
            </ul>
        </div>

        <div v-if="!$root.appType('travelata') && !$root.appType('bankiros') && !$root.appType('southwind') " class="account_flash_container">
            <img src="@/assets/img/vtb/vtb-flash.png">
            <p>Моментально отправим деньги</p>
        </div>
    </div>
</template>

<script>
import FieldsCard from "@/components/FieldsCard";
import ToogleButton from "../controls/ToogleButton.vue";
import moment from "moment";
import MainButton from "@/components/global/MainButton.vue";

export default {
    name: "Account",
    components: {MainButton, FieldsCard, ToogleButton },
    data: () => ({
        componentKey: 0,
        templateChoose: 'sbp',
        // isActiveToggle: true,
        // isTooltipFind: false,
        errorHandler: {
            status: false,
            message: ''
        },
        paymentMethod: [
            {
                status: true,
                text: 'СБП',
                value: 'sbp',
                exception: false

            },
            {
                status: false,
                text: 'Счёт',
                value: 'accountInBank',
                exception: false
            },
            {
                status: false,
                text: 'На карту',
                value: 'card',
                exception: true
            },
            // payment: this.payment
        ],

        sbpList: [
            {
                bank_name: 'Сбербанк',
                name: 'Сбербанк',
                logo: require("@/assets/img/sbp/sberbank_logo.svg"),
            },
            {
                bank_name: 'Т-Банк',
                name: 'Т-Банк',
                logo: require("@/assets/img/sbp/t-bank.png")
            },
            {
                bank_name: 'АЛЬФА-БАНК',
                name: 'Альфабанк',
                logo: require("@/assets/img/sbp/alphabank_logo.svg")
            },
            {
                bank_name: 'РНКБ Банк',
                name: 'РНКБ',
                logo: require("@/assets/img/sbp/rnbk_logo.svg")
            },
            {
                bank_name: 'Банк ВТБ',
                name: 'ВТБ',
                logo: require("@/assets/img/sbp/vtb_logo.svg")
            },
            {
                bank_name: 'Райффайзенбанк',
                name: 'Райффайзен',
                logo: require("@/assets/img/sbp/rayphayzen_logo.svg")
            },
            {
                bank_name: 'Банк ОТКРЫТИЕ',
                name: 'Открытие',
                logo: require("@/assets/img/sbp/otkritie_logo.svg")
            },
            {
                bank_name: 'Совкомбанк',
                name: 'Совкомбанк',
                logo: require("@/assets/img/sbp/sovkombank_logo.svg")
            },
            {
                bank_name: 'Почта Банк',
                name: 'Почта Банк',
                logo: require("@/assets/img/sbp/pochta_logo.svg")
            },
        ],
        bankList: [],
        selectedBank: null,
        anotherBank: {
            bank_name: 'Другой банк',
            member_name: 'other_banks',
            logo: require("@/assets/img/sbp/sbp_logo.svg"),
            selected: false
        },
        showOtherBanks: false,
        sbpSearchField: '',
        findOtherBankLogoInApi: false,
        otherBanksIsActive: false,
        fakeLogo: require("@/assets/img/sbp/sbp_logo.svg"),
        // toOtherBanks: [],
        // spbApiSearchingBank: null,
        // isSelectedBankFromOtherBanks: false,
        exceptionsTypes: ['cash', 'auto', 'nordwind'],
    }),
    computed: {
        connector() {
            return this.$root.connector;
        },
        payment: {
            get() {
                return this.anketa.conditions.initialPayment;
            },
        },
        anketa() {
            return this.$root.anketa;
        },

        creditInfo() {
            console.log("creditInformation", this.$root.anketa.creditInformation);
            let credits = this.anketa.creditInformation;
            return credits;
        },

        banksToShow(){
            let titleBanks = this.bankList.filter(bank => bank.front !== null)

            for(let i of titleBanks){
                for(let j of this.sbpList){
                    if(i.bank_name === j.bank_name){
                        // i.img = j.logo
                        i.name = j.name
                    }
                }
            }

            titleBanks.forEach(el => {
                console.log('account titleBanks el', el)
                if(!el.logoURL.trim()){
                    // console.log('лого ошибка', !el.logoURl)
                    el.showFakeLogo = true
                }else if(!this.testingToShowImage(el.logoURL)) {
                    el.showFakeLogo = true
                }
                else {
                    el.showFakeLogo = false
                }
            })
            titleBanks.sort((a, b) => a.front > b.front ? 1 : -1)
            if(this.isMobile.mdAndDown){
                titleBanks = titleBanks.slice(0, 8)
            }
            // titleBanks.push(this.anotherBank)
            return titleBanks
        },
        otherBanks(){
            let otherBanks = null
            if(!this.isMobile.mdAndDown){
                otherBanks = this.bankList.filter(bank => bank.front === null)
            } else if(this.isMobile.mdAndDown) {
                let banksToAdd = this.bankList.filter(bank =>  bank.front > 8)
                otherBanks = this.bankList.filter(bank => bank.front === null)
                otherBanks = otherBanks.concat(banksToAdd)
            }

            if(this.sbpSearchField !== ''){
                otherBanks = otherBanks.filter(el => el.bank_name.toLowerCase().includes(this.sbpSearchField.toLowerCase()))
            }
            otherBanks.sort((a, b) => a.front > b.front ? -1 : 1)
            return otherBanks
        },

        isMobile(){
            return this.$vuetify.breakpoint
        },
        gridContainerStyles(){
            let styles = {}
            if(this.isMobile.mdAndDown){
                styles.gridTemplateColumns = '2fr 2fr 2fr'
            }
            return styles
        },

        btnSelectedBankCondition(){
            let textValue = null
            if(this.selectedBank === null || this.selectedBank.bank_name === 'Другой банк'){
                textValue = 'Выберите банк'
            }else if(this.selectedBank !== null && this.selectedBank.bank_name !== 'Другой банк'){
                textValue = `Отправить в ${this.selectedBank.bank_name}`
            }
            return textValue
        },

        isAnotherBank(){
            let searchingBank = null
            if(this.selectedBank !== null){
                searchingBank = this.banksToShow.find(bank => bank.bank_name === this.selectedBank.bank_name)
            }
            console.log(searchingBank)
            return searchingBank
        },

        reqestButtonStyles(){
            let styles = {
                whiteSpace: 'normal',
                minHeight: '64px',
                height: '100% !important',
                padding: '10px 20px'
            }

            return styles

        },
        creditInfoWithoutSpace(){
            let value = null
            if(this.creditInfo.requestedSumm === undefined){
                value = '0'
            }else {
                value = this.creditInfo.requestedSumm

            }
            value = value.replaceAll(/\s+/g, '')
            value = parseInt(value)
            return value
        },

        filteredByAppTypePaymentMethod(){
            let filtered = null
            if(this.$root.appType('vtb')) {
                filtered = this.paymentMethod.filter(method => method.value !== 'card')
            }else {
                filtered = this.paymentMethod
            }
            console.log('account filtred', filtered)
            return filtered
        },

        getColorOfSeletedBank(){
            const bankColors = {
                cash: '#EB3B66',
                nordwind: '#D6181E',
                vtb: '#0160DB',
                travelata: '#EC933D',
                bankiros: '#95CE5A',
                southwind: '#D6181E',
                auto: '#EB3B66'

            }
            return bankColors[this.$root.state.type()]
        },

        otherBanksShowLogo() {
            // selectedBank === null && !findOtherBankLogoInApi
            // selectedBank !== null && testingToShowImage(selectedBank.logoURL)
            // selectedBank !== null && !testingToShowImage(selectedBank.logoURL)
            const otherBanksLogo = {};
            if(this.selectedBank === null){
                if(!this.findOtherBankLogoInApi){
                    otherBanksLogo.logo = this.anotherBank.logo
                }
            }else {
                if(this.testingToShowImage(this.selectedBank.logoURL)){
                    if(this.banksToShow.find(bank => bank.bank_name === this.selectedBank.bank_name)){

                        otherBanksLogo.logo = this.anotherBank.logo
                    }else {
                        otherBanksLogo.logo = this.selectedBank.logoURL

                    }
                }
                if(!this.testingToShowImage(this.selectedBank.logoURL)){
                    otherBanksLogo.logo = this.anotherBank.logo
                }

            }
            console.log('otherBanksShowLogo', otherBanksLogo)
            return otherBanksLogo
        },

        subtitleText(){
            let subtitle = null
            if(this.$root.appType('cash') || this.$root.appType('auto')) {
                subtitle = 'Выберите способ и деньги у вас!'
            }else if(!this.$root.appType('cash') || !this.$root.appType('auto')){
                subtitle = 'Выберите способ выплаты и деньги у вас!'
            }
            return subtitle
        }

    },
    watch: {
        reRenderComponent: function () {
            this.forceRerender();
        },
    },
    methods: {
        forceRerender() {
            this.componentKey += 1;
        },
        takeParentsInformation(flag) {
            const data = {
                success: flag,
                prev: "account",
                next: "smsPage",
            };

            this.$emit("changeTemplate", data);
        },
        async validate() {
            await this.anketa.accountBank.input(this.anketa.accountBank.value);

            if (
                (this.$refs.bik.validate() &&
                    !this.anketa.accountBank.additionalInfoBankName &&
                    !this.anketa.accountBank.errorMessages &&
                    (this.templateChoose === 'accountInBank' || this.payment > 40000) &&
                    !this.anketa.accountNumberBank.errorMessages) ||
                (this.$refs.card?.validate() && !this.templateChoose)
            ) {
                // if(this.$root.appType('nordwind')){
                //     this.$root.metrika96799237.reachGoal("get_money_choose");
                // }
                // else if(this.$root.appType('cash')) {
                //     this.$root.metrika88149184.reachGoal("get_money_choose");
                // }
                // else if(this.$root.appType('vtb')) {
                //     this.$root.metrika97329470.reachGoal("get_money_choose");
                // }
                this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("get_money_choose");
                this.$root.mindbox("Выбрал способ получения средств");

            }
            let type = this.paymentMethod.find(method => method.status === true)
            this.loanUpdate(type.value)
            this.takeParentsInformation(true);
        },

        loanUpdate(type){
            let category = null
            let cardNumber = null

            if(type === 'sbp'){
                category = 'Получение наличных денежных средств по СБП'
                cardNumber = `${this.selectedBank.member_id}`
                // if(this.$root.appType('nordwind')){
                //     this.$root.metrika96799237.reachGoal("get_money_sbp");
                // }
                // else if(this.$root.appType('cash')) {
                //     this.$root.metrika88149184.reachGoal("get_money_sbp");
                // }
                // else if(this.$root.appType('vtb')) {
                //     this.$root.metrika97329470.reachGoal("get_money_sbp");
                // }

                this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("get_money_sbp");
            }else if(type === 'card'){
                category = 'Получение наличных денежных средств на карту'
                cardNumber = this.anketa.accountCard.cleanValue()
                // if(this.$root.appType('nordwind')){
                //     this.$root.metrika96799237.reachGoal("get_money_card");
                // }else {
                //     this.$root.metrika88149184.reachGoal("get_money_card");
                // }

                this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("get_money_card");
            }else if(type === 'accountInBank') {
                category = 'Выдача наличных'
                cardNumber = ''
                // if(this.$root.appType('nordwind')){
                //     this.$root.metrika96799237.reachGoal("get_money_bank");
                // }
                // else if(this.$root.appType('cash')) {
                //     this.$root.metrika88149184.reachGoal("get_money_bank");
                // }
                // else if(this.$root.appType('vtb')) {
                //     this.$root.metrika97329470.reachGoal("get_money_bank");

                // }
                this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("get_money_bank");
            }

            const order = {
                amount: {
                    currency: "RUB",
                    value: this.creditInfoWithoutSpace,
                },
                initial_fee: {
                    currency: "RUB",
                    value: 0.0,
                },
                basket_items: [
                    {
                        account: this.getCreditInfo(this.anketa.accountNumberBank.cleanValue()),
                        bank_name: this.getCreditInfo(this.anketa.accountBank.valueAdditionalInfoBankName),
                        bik: this.getCreditInfo(this.anketa.accountBank.bik),
                        correspondent_account: this.getCreditInfo(this.anketa.accountBank.valueAdditionalInfoCorrespondentAccount),
                        category: category,
                        price: {
                            currency: "RUB",
                            value: this.creditInfoWithoutSpace,
                        },
                        count: 1,
                        card_number: cardNumber,
                    },
                ],
            };

            this.connector.loanUpdate(order)
                .then(response => {
                    if(response.success) {
                        this.connector.isRequestForSMSCode()
                    }
                    else {
                        this.errorHandler.status = true
                        this.errorHandler.message = response.message
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        getCreditInfo(value) {
            const condition = this.templateChoose;
            return condition ? value : "";
        },

        toogleHandler(paymentMethod){
            console.log(paymentMethod)
            this.templateChoose = paymentMethod
            for(let i of this.paymentMethod){
                if(i.value === paymentMethod){
                    i.status = true
                } else {
                    i.status = false
                }
            }
            if(paymentMethod === 'sbp'){
                const date = moment().format("YYYY-MM-DD HH:mm:ss")
                this.connector.getBanks(date)
                    .then(response => {
                        console.log('get_banks response', response)
                        if(response.success){
                            console.log(response)
                            this.bankList = response.message.participants
                        }else {
                            this.errorHandler.status = true
                            this.errorHandler.message = response.message
                        }
                    })
            }
        },

        selectBank(e){
            let currentBank = e.currentTarget.dataset.bankName
            console.log('sbp currentBank', currentBank)
            this.selectedBank = this.bankList.find(bank => bank.bank_name === currentBank)
            if(e.currentTarget.id === 'other_banks') {
                this.showOtherBanks = true
                this.selectedBank = this.banksToShow[0]
                this.otherBanksIsActive = true
                this.anotherBank.selected = true
            }else {
                this.findOtherBankLogoInApi = false
                this.otherBanksIsActive = false
                this.anotherBank.selected = false
            }
        },

        chooseAnotherBank(e){
            let bankName = e.currentTarget.dataset.bankName

            // let searchingBank = null
            console.log(' account bankName', bankName)

            this.selectedBank = this.otherBanks.find(bank => bank.bank_name === bankName)
            this.showOtherBanks = false

            this.findOtherBankLogoInApi = true
            // this.connector.getBanks()
            //     .then(response => {
            //         searchingBank = response.dictionary.find(bank => bank.bankName === bankName)
            //         this.selectedBank = this.otherBanks.find(bank => {
            //             return bank.bank_name === searchingBank.bankName
            //         })
            //         this.findOtherBankLogoInApi = true
            //         this.showOtherBanks = false
            //         this.selectedBank.logo = searchingBank.logoURL
            //     })
        },

        confirmBank(){
            this.loanUpdate('sbp')
            this.takeParentsInformation(true)
        },

        otherBanksBack(){
            this.showOtherBanks = false
            this.selectedBank = this.anotherBank
            this.anotherBank.selected = false
        },

        // creditInfoWithoutSpace(){
        //     // let value = null
        //     // if(this.creditInfo.requestedSumm === undefined){
        //     //     value = '0'
        //     // }else {
        //     //     value = this.creditInfo.requestedSumm

        //     // }
        //     let value = this.creditInfo.requestedSumm
        //     value = value.replaceAll(/\s+/g, '')
        //     value = parseInt(value)
        //     console.log('creditInfoWithoutSpace', value)
        //     return value
        // }

        testingToShowImage(str){
            let regExp = [/http/, /https/]
            let flag = false
            regExp.forEach(item => {
                if(item.test(str)){
                    flag = true
                }else {
                    flag = false
                }
            })
            console.log('sbp flags', flag)
            return flag
        },

        addClass(cssClass){
            let searchingClass = null
            // console.log('addClass', this.exceptionsTypes.find(this.$root.state.type()))
            if(this.exceptionsTypes.find(el => el === this.$root.state.type())) {
                searchingClass = `${cssClass}`
            }else {
                searchingClass = `${this.$root.state.type()}_${cssClass}`
            }
            return searchingClass
        },

    },
    mounted() {
        if(this.templateChoose === 'sbp'){
            this.toogleHandler('sbp')
        }
    },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_vtb-variables.scss";
  @import "@/assets/scss/_travelata-variables.scss";

.desc-tooltip {
    font-family: Roboto !important;
}

.account {

    &_subtitle {
        // color: #0A0A0D !important;
        color: #656565;
        font-weight: 700 !important;
    }

    &_flash_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 200px;
        width: 100%;
        height: 24px;
        margin: 0 auto;

        & > img {
            width: 24px;
            height: 24px;
        }

        & > p {
            @include vtb-form-label;
            color: #656565;
            margin-bottom: 0;

        }

    }
}
.sbp {
    font-family: 'Roboto';

    &_title {
        font-family: Roboto !important;
        font-size: 12px !important;
        font-weight: 700 !important;
        line-height: 16px !important;
        letter-spacing: -0.08px !important;
        text-align: center !important;
        margin-bottom: 20px;
    }

    &_container {
        display: grid;
        justify-content: space-between;
        justify-items: center;
        // flex-wrap: wrap;
        grid-template-columns: repeat(5, 1fr);
        gap: 40px;

        @media (max-width: 400px) {
            gap: 30px;
        }
        @media (max-width: 350px) {
            gap: 20px;
        }
        @media (max-width: 330px) {
            gap: 10px;
        }

        &_item {
            width: 82px;
            height: 82px;
            padding: 16px 8px 16px 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 12px;
            box-shadow: 1px 8px 25px 0px rgba(149,157,165,0.19);
            -webkit-box-shadow: 1px 8px 25px 0px rgba(149,157,165,0.19);
            -moz-box-shadow: 1px 8px 25px 0px rgba(149,157,165,0.19);
            margin-bottom: 20px;

            &_image {
                width: 40px;
                height: 40px;
            }

            p {
                font-size: 10px !important;
                font-weight: 700 !important;
                line-height: 16px;
                letter-spacing: -0.08px;
                margin: 0 !important;
                color: #0A0A0D !important;
            }
        }
    }

}



    .sbp_request-button {
        // font-size: 1rem;
        display: block;
        max-width: 100%;
        overflow-wrap: break-word;
    }


.selected-bank {
    border: 2px solid v-bind(getColorOfSeletedBank);
}

.other-banks {
    position: relative;

    &_back {
        width: 35px;
        cursor: pointer;
        position: absolute;
        top: -25px;
        left: 0;

    }

    &_title {
        font-family: Neris !important;
        font-weight: 900 !important;
        font-size: 24px !important;
        line-height: 32.16px !important;
        letter-spacing: -0.5px !important;
        margin-bottom: 20px;
        margin-left: 20px;
        padding-top: 30px;
    }

    &_search-field {
        margin-bottom: 40px !important;

        &_container {
            position: relative;

        }
        &_img {
            position: absolute;
            top: 25px;
            right: 17px;
        }
    }

    &_list {
        list-style-type: none;
        // padding-left: 0 !important;

        &_item {
            margin: 0 0 20px;
            &_text {
                color: #0A0A0D !important;
                font-weight: 400 !important;
                font-size: 16px !important;
                line-height: 18.75px !important;
                cursor: pointer;
                margin: 0 !important;
                text-align: left;
            }
        }
    }
}

.btnSelectedBankCondition {
    white-space: normal !important;
}

// .v-btn, #request-button {
//     white-space: normal !important;
//     min-height: 64px !important;
//     height: 100% !important;
//     padding: 10px 20px !important;
// }

.info-toggle-item {
    // margin: 32px 0px 0px 0px;
    margin-bottom: 16px;
    width: 100%;
    padding: 0;

    &-1 {
        .v-text-field__details {
            min-height: 0 !important;
            transition: 0.3s;
            margin-bottom: 4px !important;

            .v-messages {
                min-height: 0;

                &.error--text {
                    margin-bottom: 8px;
                }
            }
        }
    }
}
.account_text {
    @include tr-text(12px, 800, 16px);
    font-family: 'OpenSansEB';
    color: #333333;
}

.account_form_title {
    text-align: center !important;
    margin-bottom: 20px;

    &.cash, &.auto {
        font-family: Roboto !important;
        font-size: 12px !important;
        font-weight: 900 !important;
        line-height: 16px !important;
        letter-spacing: -0.4px !important;
    }

    &.nordwind {
        font-family: Roboto !important;
        font-size: 12px !important;
        font-weight: 900 !important;
        line-height: 16px !important;
        letter-spacing: -0.4px !important;
    }

    &.vtb {
        font-family: VtbBold !important;
        font-size: 12px !important;
        font-weight: 700 !important;
        line-height: 16px !important;
        letter-spacing: -0.4px !important;
    }
    &.travelata {
        @include tr-text(12px, 800, 16px);
        font-family: 'OpenSansEB' !important;
        color: #333333;
    }

    &.southwind {
        font-family: Roboto !important;
        font-size: 12px !important;
        font-weight: 900 !important;
        line-height: 16px !important;
        letter-spacing: -0.4px !important;
    }
}

</style>
